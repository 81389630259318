<template>
    <div>
        <button class="button is-link is-light is-pulled-right is-small" v-on:click="add()">
            <strong>Cadastrar</strong>
        </button>  
        <br>
        <br>

        <div class="modal" v-bind:class="{ 'is-active': isShowModal }">
            <div class="modal-background" v-on:click="isShowModal = false"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                <p class="modal-card-title">Cadastrar</p>
                <button class="delete" aria-label="close" v-on:click="isShowModal = false"></button>
                </header>
                <section class="modal-card-body">
                    <form v-if="!this.mostraOutrasInfoVersaoConfig && !this.mostraCompartilhamento">
                        <div class="field is-horizontal">
                            <div class="field-label is-normal">
                                <label class="label"><small>Descrição</small></label>
                            </div>                            
                            <div class="field-body">
                                <div class="field is-normal">
                                    <input class="input" type="text" placeholder="Informe a descrição" v-model="outraInformacao.descricao">
                                </div>
                            </div>
                        </div>

                        <div class="field is-horizontal">
                            <div class="field-label is-normal">
                                <label class="label"><small>Nome da Fórmula</small></label>
                            </div>                            
                            <div class="field-body">
                                <div class="field is-normal">
                                    <input class="input" type="text" placeholder="Informe o nome da fórmula" v-model="outraInformacao.nome_formula">
                                </div>
                            </div>
                        </div>

                        <div class="field is-horizontal">
                            <div class="field-label is-normal">
                                <label class="label"><small>Comentários</small></label>
                            </div>                            
                            <div class="field-body">
                                <div class="field is-normal">
                                    <textarea class="textarea" placeholder="Comente sobre essa outra informação" v-model="outraInformacao.comentario">
                                    </textarea>
                                </div>
                            </div>
                        </div>
                    </form>  

                    <form v-if="this.mostraCompartilhamento">
                        <div class="field is-horizontal">
                            <div class="field-label is-normal">
                                <label class="label"><small>Versão</small></label>
                            </div>                            
                            <div class="field-body">
                                <div class="field">
                                    <div class="control">
                                        <div class="select is-fullwidth">
                                        <select v-model="outrasInformacoesCompartilhar.versao_plano_contas">
                                            <option v-for="versao_plano_contas in versoesPlanoContas" :key="versao_plano_contas.id" :value="versao_plano_contas">
                                                {{versao_plano_contas.descricao}}
                                            </option>
                                        </select>
                                        </div>    
                                    </div>
                                </div>  
                            </div>
                        </div>
                    </form>  

                    <form v-if="this.mostraOutrasInfoVersaoConfig">

                        <div class="field is-horizontal">
                            <div class="field-label is-normal">
                                <label class="label"><small>Descrição</small></label>
                            </div>                            
                            <div class="field-body">
                                <div class="field is-normal">
                                    <input class="input" type="text" placeholder="Informe a descrição" v-model="outrasInformacoesVersaoConfig.descricao">
                                </div>
                            </div>
                        </div>

                        <div class="field is-horizontal">
                            <div class="field-label is-normal">
                                <label class="label"><small>Sequencial</small></label>
                            </div>                            
                            <div class="field-body">
                                <div class="field is-normal">
                                    <input class="input" type="text" v-model="outrasInformacoesVersaoConfig.sequencial">
                                </div>
                            </div>
                        </div>


                        <div class="field is-horizontal">
                            <div class="field-label">
                                <label class="label"><small>Calculado</small></label>
                            </div>                            

                            <div class="field-body">
                                <div class="field is-normal">
                                    <select class="select is-fullwidth" v-model="outrasInformacoesVersaoConfig.eCalculado">
                                        <option value="S">Sim</option>
                                        <option value="N">Não</option>
                                    </select>
                                </div>
                            </div>    
                        </div>    

                        <div class="field is-horizontal">
                            <div class="field-label is-normal">
                                <label class="label"><small>Desejada</small></label>
                            </div>                            

                            <div class="field-body">
                                <div class="field">
                                    <select class="select is-fullwidth" v-model="outrasInformacoesVersaoConfig.tipo_evolucao_desejada">
                                        <option value="A">Alta</option>
                                        <option value="B">Baixa</option>
                                    </select>
                                </div>   
                            </div>    
                        </div>

                        <div class="field is-horizontal">
                            <div class="field-label is-normal">
                                <label class="label"><small>Fórmula</small></label>
                            </div>                            
                            <div class="field-body">
                                <div class="field is-normal">
                                    <textarea class="textarea" placeholder="Informe a fórmula para preenchimento" v-model="outrasInformacoesVersaoConfig.formula" :disabled="outrasInformacoesVersaoConfig.eCalculado == 'N'">
                                    </textarea>
                                </div>
                            </div>
                        </div>
                    </form>  
                </section>
                <footer class="modal-card-foot">
                <button class="button is-success" @click="save()">Salvar</button>
                <button class="button" v-on:click="isShowModal = false">Cancelar</button>
                </footer>
            </div>
        </div>        

        <div class="columns">
            <div class="column is-two-fifths">
                <div class="card">
                    <header class="panel-heading has-background-link-dark has-text-light">
                        <nav class="level">
                            <div class="level-left">
                                <p class="has-text-white-bis">
                                    <small>Outras Informações</small>
                                </p>
                            </div>    
                        </nav>    
                    </header>
                    <div class="card-content">
                        <div class="table-container">                
                            <table class="table is-striped is-narrow is-hoverable is-fullwidth">
                                <thead>
                                    <tr>
                                        <th><small>Descrição</small></th>
                                        <th><small>Comentários</small></th>
                                        <th colspan="4" class="has-text-centered"><small>Ações</small></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(oiv, index) in outrasInformacoes" :key="oiv.id">
                                        <td><small>{{oiv.descricao}}</small></td>
                                        <td><small>{{oiv.comentario}}</small></td>
                                        <td class="has-text-centered"><small><button class="button is-ghost is-small is-inverted is-link" data-tooltip="Editar" @click="edit(index)" :disabled="mostraCompartilhamento || mostraOutrasInfoVersaoConfig"><span class="fas fa-edit"></span></button></small></td>
                                        <td class="has-text-centered"><small><button class="button is-ghost is-small is-inverted is-link" @click="erase(index)" :disabled="mostraCompartilhamento || mostraOutrasInfoVersaoConfig"><span class="fas fa-trash-alt"></span></button></small></td>
                                        <td class="has-text-centered" style="width:15px"><button class="button is-ghost is-small is-inverted is-link has-tooltip-left" data-tooltip="Compartilhar" @click="callCompartilhamento(oiv)" :disabled="mostraCompartilhamento || mostraOutrasInfoVersaoConfig"><span class="fas fa-share-alt"></span></button></td>
                                        <td class="has-text-centered"><small><button class="button is-ghost is-small is-inverted is-link" @click="callOutrasInformacoesConfig(oiv)" :disabled="mostraCompartilhamento || mostraOutrasInfoVersaoConfig"><span class="fas fa-th-list"></span></button></small></td>
                                    </tr>
                                </tbody>    
                            </table>
                        </div>    
                    </div>    
                </div>    
            </div>

            <div class="column" v-show="this.mostraCompartilhamento">
                <div class="card">
                    <header class="panel-heading has-background-grey">
                        <nav class="level">
                            <div class="level-left">
                                <p class="has-text-white-bis">
                                    <small>Compartilhar {{ this.outrasInformacoesSelecionada.descricao }}</small>
                                </p>
                            </div>    
                            <div class="level-right">
                                <small><a v-on:click="mostraCompartilhamento = false"><span class="fa fa-times has-text-black has-text-white-bis"></span></a></small>
                            </div>
                        </nav>    
                    </header>

                    <div class="card-content">
                        <div class="table-container">                
                            <table class="table is-narrow is-fullwidth is-striped is-hoverable">
                                <thead>
                                    <tr>
                                        <th class="has-text-left"><small>Versão</small></th>
                                        <th colspan="2" class="has-text-centered"><small>Ação</small></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(oiVersao, index) in outrasInformacoesVersoes" :key="oiVersao.id">
                                        <td class="has-text-left"><small>{{oiVersao.versao_plano_contas.descricao}}</small></td>
                                        <td class="has-text-centered"><small><button class="button is-ghost is-small is-inverted is-link" @click="erase(index)"><span class="fas fa-trash-alt"></span></button></small></td>
                                    </tr>
                                </tbody>    
                            </table>
                        </div>    
                    </div>
                </div>
            </div>

            <div class="column" v-show="this.mostraOutrasInfoVersaoConfig">
                <div class="card">
                    <header class="panel-heading has-background-grey">
                        <nav class="level">
                            <div class="level-left">
                                <p class="has-text-white-bis">
                                    <small>Linhas de Outras Informações {{ this.outrasInformacoesSelecionada.descricao }}</small>
                                </p>
                            </div>    
                            <div class="level-right">
                                <small><a v-on:click="mostraOutrasInfoVersaoConfig = false"><span class="fa fa-times has-text-black has-text-white-bis"></span></a></small>
                            </div>
                        </nav>    
                    </header>

                    <div class="card-content">
                        <div class="table-container">                
                            <table class="table is-narrow is-fullwidthtable is-striped is-narrow is-hoverable is-fullwidth">
                                <thead>
                                    <tr>
                                        <th class="has-text-left"><small>Sequencial</small></th>
                                        <th class="has-text-left"><small>Descrição</small></th>
                                        <th colspan="2" class="has-text-centered"><small>Ação</small></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(linha, index) in linhasOutrasInformacoes" :key="linha.id">
                                        <td class="has-text-left"><small>{{linha.sequencial}}</small></td>
                                        <td class="has-text-left"><small>{{linha.descricao}}</small></td>
                                        <td class="has-text-centered"><small><button class="button is-ghost is-small is-inverted is-link" @click="edit(index)"><span class="fas fa-edit"></span></button></small></td>
                                        <td class="has-text-centered"><small><button class="button is-ghost is-small is-inverted is-link" @click="erase(index)"><span class="fas fa-trash-alt"></span></button></small></td>
                                    </tr>
                                </tbody>    
                            </table>
                        </div>    
                    </div>
                </div>
            </div>
        </div>   
    </div>        

</template>

<script>
    // import _ from 'lodash'
    import {http} from '../../services/config'

    export default {
        name: "OutrasInformacoesVersao",

        data(){
            return{
                outraInformacao: {
                    id: null,
                    descricao: null,
                    comentario: null,
                    nome_formula: null,
                    usuario: null
                },
                outrasInformacoesCompartilhar: { id: null,
                    outras_informacoes_versao: null,
                    versao_plano_contas: null
                },
                outrasInformacoesVersaoConfig: {
                    id: null,
                    sequencial: null,
                    descricao: null,
                    formula: null,
                    tipo_evolucao_desejada: "A",
                    eCalculado: "S",
                    outrasInformacoesVersao: null
                },
                versoesPlanoContas: [],
                outrasInformacoes: [],
                linhasOutrasInformacoes: [],
                outrasInformacoesVersoes: [],
                isShowModal: false,
                mostraOutrasInfoVersaoConfig: false, 
                mostraCompartilhamento: false,
                outrasInformacoesSelecionada: {
                    id: null,
                    descricao: null
                },
                previous: null,
                next: null,
                page: null,
                numpages: null
            }
        },

        methods: {
            add: function() {
                this.clearFields()
                this.isShowModal = true              
            },

            edit: function(index){
                if (!this.mostraOutrasInfoVersaoConfig && !this.mostraCompartilhamento) {
                    this.outraInformacao.id = this.outrasInformacoes[index].id
                    this.outraInformacao.descricao = this.outrasInformacoes[index].descricao
                    this.outraInformacao.nome_formula = this.outrasInformacoes[index].nome_formula
                    this.outraInformacao.comentario = this.outrasInformacoes[index].comentario
                    this.outraInformacao.usuario = this.outrasInformacoes[index].usuario
                    this.outraInformacao.index = index
                }
                else {
                    this.outrasInformacoesVersaoConfig.id = this.linhasOutrasInformacoes[index].id
                    this.outrasInformacoesVersaoConfig.descricao = this.linhasOutrasInformacoes[index].descricao
                    this.outrasInformacoesVersaoConfig.sequencial = this.linhasOutrasInformacoes[index].sequencial

                    if (this.linhasOutrasInformacoes[index].e_calculado) {
                        this.outrasInformacoesVersaoConfig.eCalculado = "S"
                    } else {
                        this.outrasInformacoesVersaoConfig.eCalculado = "N"
                    }

                    this.outrasInformacoesVersaoConfig.formula = this.linhasOutrasInformacoes[index].formula
                    this.outrasInformacoesVersaoConfig.outrasInformacoesVersao = this.outrasInformacoesSelecionada
                    this.outrasInformacoesVersaoConfig.tipo_evolucao_desejada = this.linhasOutrasInformacoes[index].tipo_evolucao_desejada
                    this.outrasInformacoesVersaoConfig.index = index
                }
                this.isShowModal = true
            },

            erase: function(index){
                if (window.confirm('Confirma exclusão?')) {
                    if (!this.mostraOutrasInfoVersaoConfig && !this.mostraCompartilhamento) {
                        http.delete('/outrasInformacoesVersao/excluir/'+this.outrasInformacoes[index].id).then(response =>{
                            this.outrasInformacoes.splice(index, 1)
                            if (response.status == 204) {
                                alert('Registro excluído!')
                            }
                        }).catch(e => {
                            console.log(e);
                        })
                    } else if (this.mostraCompartilhamento) {
                        http.delete('/outrasInformacoesVersao/excluirVersoes/'+this.outrasInformacoesVersoes[index].id).then(response =>{
                            this.outrasInformacoesVersoes.splice(index, 1)
                            if (response.status == 204) {
                                alert('Registro excluído!')
                            }
                        }).catch(e => {
                            console.log(e);
                        })
                    }
                    else {
                        http.delete('/outrasInformacoesVersaoConfig/excluir/'+this.linhasOutrasInformacoes[index].id).then(response =>{
                            this.linhasOutrasInformacoes.splice(index, 1)
                            if (response.status == 204) {
                                alert('Registro excluído!')
                            }
                        }).catch(e => {
                            console.log(e);
                        })
                    }
                }
            },

            callCompartilhamento: function(outraInfo) {
                this.mostraCompartilhamento = true
                this.outrasInformacoesSelecionada = outraInfo
                http.get('outrasInformacoesVersao/listarVersoes/', {params: {
                    idOutrasInformacoesVersao: this.outrasInformacoesSelecionada.id
                }}).then(response => {
                    this.outrasInformacoesVersoes = response.data.results
                })
            },

            clearFields: function() {
                if (!this.mostraOutrasInfoVersaoConfig && !this.mostraCompartilhamento) {
                    this.outraInformacao = { id: null,
                    descricao: null,
                    comentario: null,
                    nome_formula: null,
                    usuario: null,
                    index: null }
                } else if (this.mostraCompartilhamento) {
                    this.outrasInformacoesCompartilhar = {id: null,
                        outras_informacoes_versao: null,
                        versao_plano_contas: null }
                } else {
                    this.outrasInformacoesVersaoConfig = {
                        id: null,
                        outrasInformacoesVersao: this.outrasInformacoesSelecionada,
                        sequencial: null,
                        descricao: null,
                        formula: null,
                        tipo_evolucao_desejada: "A",
                        eCalculado: "S",
                        index: null
                    }
                }
            },

            callOutrasInformacoesConfig: function(outraInfo) {
                this.mostraOutrasInfoVersaoConfig = true
                this.outrasInformacoesSelecionada = outraInfo
                http.get('outrasInformacoesVersaoConfig/listar/', {params: {
                    idOutrasInformacoesVersao: this.outrasInformacoesSelecionada.id
                }}).then(response => {
                    this.linhasOutrasInformacoes = response.data.results
                })
            },

            save: function(){
                var data

                if (!this.mostraOutrasInfoVersaoConfig && !this.mostraCompartilhamento) {
                    if (this.outraInformacao.descricao == null) {
                        alert('Descrição não preenchida!')
                        return
                    }

                    if (this.outraInformacao.nome_formula == null) {
                        alert('Nome da fórmula não preenchida!')
                        return
                    }

                    data = {id: this.outraInformacao.id,
                        usuario: this.$store.state.idUser,
                        descricao: this.outraInformacao.descricao,
                        nome_formula: this.outraInformacao.nome_formula, 
                        comentario: this.outraInformacao.comentario
                    }

                    let formData = new FormData()

                    formData.append('id', data.id)
                    formData.append('usuario', data.usuario)
                    formData.append('descricao', data.descricao)
                    formData.append('nome_formula', data.nome_formula)
                    formData.append('comentario', data.comentario)

                    if (this.outraInformacao.id == null) {
                        http.post('/outrasInformacoesVersao/adicionar', formData).then(response =>{
                            if (response.data.id != undefined && response.data.id != '') {
                                this.outraInformacao.id = response.data.id
                                this.outrasInformacoes.push(this.outraInformacao)
                                this.clearFields()
                            } else {
                                alert(response.request.responseText)
                            }
                        }).catch(e => {
                            alert(e.response.data);
                            return
                        })
                    } 
                    else {
                        http.put('/outrasInformacoesVersao/editar/'+this.outraInformacao.id, formData).then(response =>{
                            this.outrasInformacoes[this.outraInformacao.index].descricao = response.data.descricao
                            this.outrasInformacoes[this.outraInformacao.index].nome_formula = this.outraInformacao.nome_formula
                            this.outrasInformacoes[this.outraInformacao.index].comentario = this.outraInformacao.comentario
                        }).catch(e => {
                            alert(e.response.data);
                            return
                        })
                    }
                } else if (this.mostraCompartilhamento) {
                    if (this.outrasInformacoesCompartilhar.versao_plano_contas == null) {
                        alert('Versão não informada!')
                        return
                    }

                    data = {id: this.outrasInformacoesCompartilhar.id,
                        outras_informacoes_versao: this.outrasInformacoesSelecionada.id,
                        versao_plano_contas: this.outrasInformacoesCompartilhar.versao_plano_contas.id
                    }

                    if (this.outrasInformacoesCompartilhar.id == null) {
                        http.post('/outrasInformacoesVersao/adicionarVersoes', data).then(response =>{
                            if (response.data.id != undefined && response.data.id != '') {
                                this.outrasInformacoesCompartilhar.id = response.data.id
                                this.outrasInformacoesVersoes.push(this.outrasInformacoesCompartilhar)
                                this.clearFields()
                            } else {
                                alert(response.request.responseText)
                            }
                        }).catch(e => {
                            alert(e.response.data);
                            return
                        })
                    } 
                }    
                else {
                    if (this.outrasInformacoesVersaoConfig.descricao == null) {
                        alert('Descrição não informada!')
                        return
                    }

                    if (this.outrasInformacoesVersaoConfig.sequencial == null) {
                        alert('Sequencial não informado!')
                        return
                    }

                    if (this.outrasInformacoesVersaoConfig.formula == null && this.outrasInformacoesVersaoConfig.eCalculado == "S") {
                        alert('Fórmula não informada!')
                        return
                    }

                    data = {id: this.outrasInformacoesVersaoConfig.id,
                        outras_informacoes_versao: this.outrasInformacoesSelecionada.id,
                        sequencial: this.outrasInformacoesVersaoConfig.sequencial,
                        descricao: this.outrasInformacoesVersaoConfig.descricao,
                        formula: this.outrasInformacoesVersaoConfig.formula,
                        tipo_evolucao_desejada: this.outrasInformacoesVersaoConfig.tipo_evolucao_desejada,
                        e_calculado: false
                    }

                    if (this.outrasInformacoesVersaoConfig.eCalculado == 'S') (
                        data.e_calculado = true
                    )

                    if (this.outrasInformacoesVersaoConfig.id == null) {
                        http.post('/outrasInformacoesVersaoConfig/adicionar', data).then(response =>{
                            if (response.data.id != undefined && response.data.id != '') {
                                this.outrasInformacoesVersaoConfig.id = response.data.id
                                this.linhasOutrasInformacoes.push(this.outrasInformacoesVersaoConfig)
                                this.clearFields()
                            } else {
                                alert(response.request.responseText)
                            }
                        }).catch(e => {
                            alert(e.response.data);
                            return
                        })
                    } 
                    else {
                        http.put('/outrasInformacoesVersaoConfig/editar/'+this.outrasInformacoesVersaoConfig.id, data).then(response =>{
                            this.linhasOutrasInformacoes[this.outrasInformacoesVersaoConfig.index].outras_informacoes_versao = this.outrasInformacoesSelecionada
                            this.linhasOutrasInformacoes[this.outrasInformacoesVersaoConfig.index].sequencial = response.data.sequencial
                            this.linhasOutrasInformacoes[this.outrasInformacoesVersaoConfig.index].descricao = response.data.descricao
                            this.linhasOutrasInformacoes[this.outrasInformacoesVersaoConfig.index].e_calculado = response.data.e_calculado
                            this.linhasOutrasInformacoes[this.outrasInformacoesVersaoConfig.index].formula = response.data.formula
                            this.linhasOutrasInformacoes[this.outrasInformacoesVersaoConfig.index].tipo_evolucao_desejada = response.data.tipo_evolucao_desejada
                        }).catch(e => {
                            alert(e.response.data);
                            return
                        })
                    }                    
                }

            }
        },

        mounted: function() {
        },
        
        created: function() {
            http.get('versoesPlanoContas/listar').then(response => {
                this.versoesPlanoContas = response.data.results
            })

            http.get('outrasInformacoesVersao/listar/').then(response => {
                this.outrasInformacoes = response.data.results
                this.previous = response.data.previous
                this.next = response.data.next
                this.page = response.data.page
                this.numpages = response.data.numpages
            })
        }
    }
</script>

<style scoped>
</style>