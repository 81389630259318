<template>
  <section class="section">
    <div class="container">
      <section class="hero">
        <div class="hero-body">
          <div class="columns">
            <div class="column is-half">
              <br>
              <br>
              <br>
              <br>
              <br>
              <br>
              <p class="title has-text-danger is-1">BOARD</p>
              <p class="subtitle has-text-black">Personalize suas publicações financeiras, <br/>distribua e acesse em qualquer lugar, <br/>em qualquer dispositivo.</p>
            </div>
            <div class="column">
              <figure class="image">
                <img src="../../assets/img/capa-generica-inclinada.jpg" />
              </figure>              
            </div>
          </div>

        </div>
      </section>

      <section class="hero">
        <div class="hero-body">
          <div class="columns">
            <div class="column has-text-centered">
              <div class="has-text-black has-background-light-90 is-size-1 has-text-weight-bold">
                <br>
                <br>
                <p>A maneira mais simples<br/> para entregar informações <br/> econômico-financeiras indispensáveis para a gestão empresarial</p>
                <br>
                <br>
              </div>				
            </div>
          </div>
        </div>
      </section>

      <section class="hero has-background-light">
        <div class="hero-body">

          <div class="columns">
             <div class="column is-half">
                <br>
              <div class="has-text-black has-background-light is-size-3">Crie Balanços Patrimoniais e DRE's de acordo com a sua necessidade. O <span class="has-text-danger has-text-weight-bold">BOARD</span> também fornece modelos de publicações para que você possa aplicar rapidamente e surpreender seus clientes
                <br>
              </div>				
            </div>
            <div class="column">
              <br>
              <br>
              <br>
              <p class="title is-1 has-text-right"><span class="fas fa-file-invoice-dollar has-text-success"></span><br/>Demonstrações<br/>Financeiras</p>
            </div>
          </div>
        </div>
      </section>

      <section class="hero has-background-light">
        <div class="hero-body">

          <div class="columns">
             <div class="column is-half">
                <br>
                <br>
                <br>
                <br>
                <br>
                <br>
                <br>
                <p class="title is-1 has-text-left"><span class="fas fa-chart-line has-text-info"></span><br/>Indicadores<br/>Gerenciais</p>
            </div>
            <div class="column">
              <br>
              <div class="has-text-black has-background-light is-size-3">Tanto o balanco patrimonial quanto a demonstração de resultado do exercício são fontes espetaculares de informações 
                  gerenciais. O <span class="has-text-danger has-text-weight-bold">BOARD</span> fornece diversos indicadores econômico-financeiros extraídos destas publicações.
                  Você tambem pode criar seus indicadores. Qualquer informação do seu balancete, balanço patrimonial e DRE podem ser extraídos 
                  com uma simples fórmula.
                  <br>
                </div>				
            </div>
          </div>
        </div>
      </section>

      <section class="hero has-background-light">
        <div class="hero-body">

          <div class="columns">
             <div class="column is-half">
                <br>
              <div class="has-text-black has-background-light is-size-3">Visualize a evolução das informações econômico-financeiras de forma sofisticada e simples. Surpreenda seus clientes com um caderno fantástico contemplando as publicações financeiras e todos os indicadores gerenciais.
                <br>
              </div>				
            </div>
            <div class="column">
              <br>
              <br>
              <br>
              <br>
              <p class="title is-1 has-text-right"><span class="fas fa-tv has-text-primary"></span><br/> Apresentação</p>
            </div>
          </div>
        </div>
      </section>

      <!-- <section class="hero is-small is-black">
        <div class="hero-body">
          <div class="columns">
            <div class="column">
              <br>
              <br>
              <div class="has-text-white has-background-black is-size-2 has-text-weight-bold">Modele, Invista e acompanhe a evolução do seu patrimônio.
                <br>
                <br>
              </div>				
            </div>

            <div class="column is-half has-text-right">
              <br>
              <br>
              <br>
              <p class="title has-text-warning is-1">QUIVER</p>
              <p class="subtitle has-text-white">Gestão de carteiras de investimentos</p>
            </div>



          </div>
        </div>
      </section>

      <br>

      <section class="hero is-small has-background-grey-lighter">
        <div class="hero-body">
          <div class="columns">
            <div class="column is-half">
              <br>
              <br>
              <br>
              <br>
              <p class="title has-text-success is-1">PRO MODEL</p>
              <p class="subtitle has-text-black">Simulação de negócios (em breve)</p>
            </div>
            <div class="column">
              <br>
              <div class="has-text-black has-background-grey-lighter is-size-2 has-text-weight-bold">Simule e veja os impactos financeiros das tomadas de decisões no resultado.
                <br>
                <br>
              </div>				
            </div>
          </div>
        </div>
      </section> -->
    </div>

    <br>
    <br>

    <footer class="footer has-background-link">
      <div class="content">
        <div class="columns">
          <div class="column is-four-fifths"></div>
          <div class="column has-text-centered has-text-white">Contatos</div>
        </div>
        <div class="columns has-text-white">
          <div class="column is-four-fifths"><strong class="has-text-white">LineApps</strong> | Soluções alinhadas ao sua necessidade | versão: 202412-01</div>
          <div class="column is-size-3 has-text-centered has-text-white"><strong class="has-text-white"><a class="has-text-white fab fa-whatsapp" href="https://wa.me/5581994032445" target="_blank"></a></strong></div>
        </div>
      </div>
    </footer>

  </section>

</template>

<script>
    // import {http} from '../../services/config'
    export default {
        name: "MenuPrincipal",

        methods: {
        },

        mounted: function() {
        },
        
        created: function() {
        }

    }
</script>

<style scoped>
</style>