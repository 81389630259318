<template>
    <div>
        <br>
        <button class="button is-link is-light is-pulled-right is-small" v-on:click="add()" :disabled="!mostraValoresReferencia">
            <strong>Cadastrar</strong>
        </button>  

        <form>
            <div class="field is-horizontal">

                <div class="field-body">
                    <div class="field is-narrow">
                        <div class="control">
                            <div class="select is-narrow">
                                <select v-model="cmbGrupoIndicador">
                                    <option value="0">TODOS OS GRUPOS DE INDICADORES</option>
                                    <option v-for="gi in gruposIndicadores" :key="gi.id" :value="gi.id">
                                        {{gi.descricao}}
                                    </option>
                                </select>
                            </div>    
                        </div>
                    </div>  

                    <div class="field">                    
                        <button type='submit' class="button is-link" @click.stop.prevent="filterRecords">Filtrar</button>
                    </div>    
                </div>

            </div>
        </form>  

        <hr>

        <div class="modal" v-bind:class="{ 'is-active': isShowModal }">
            <div class="modal-background" v-on:click="isShowModal = false"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                <p class="modal-card-title">Cadastrar</p>
                <button class="delete" aria-label="close" v-on:click="isShowModal = false"></button>
                </header>
                <section class="modal-card-body">
                    <form>
                        <div class="field is-horizontal">
                            <div class="field-label is-normal">
                                <label class="label"><small>Status</small></label>
                            </div>                            

                            <div class="field-body">
                                <div class="field">
                                    <select class="select is-fullwidth" v-model="valorReferenciaIndicador.status">
                                        <option value="1">Péssimo</option>
                                        <option value="2">Ruim</option>
                                        <option value="3">Normal</option>
                                        <option value="4">Bom</option>
                                        <option value="5">Excelente</option>
                                    </select>
                                </div>   
                            </div>    
                        </div>

                        <div class="field is-horizontal">
                            <div class="field-label is-normal">
                                <label class="label"><small>Faixa Inicial</small></label>
                            </div>                            
                            <div class="field-body">
                                <div class="field is-narrow">
                                    <input class="input" type="number" placeholder="Informe a faixa inicial" v-model="valorReferenciaIndicador.faixa_inicial">
                                </div>
                            </div>
                        </div>

                        <div class="field is-horizontal">
                            <div class="field-label is-normal">
                                <label class="label"><small>Faixa Final</small></label>
                            </div>                            
                            <div class="field-body">
                                <div class="field is-narrow">
                                    <input class="input" type="number" placeholder="Informe a faixa inicial" v-model="valorReferenciaIndicador.faixa_final">
                                </div>
                            </div>
                        </div>
                    </form>
                </section>
                <footer class="modal-card-foot">
                    <button class="button is-success" @click="save()">Salvar</button>
                    <button class="button" v-on:click="isShowModal = false">Cancelar</button>
                </footer>
            </div>
        </div>        

        <div class="columns" v-show="this.showInfo">
            <div class="column is-two-fifths">
                <div class="card">
                    <header class="panel-heading has-background-link-dark has-text-light">
                        <nav class="level">
                            <div class="level-left">
                                <p class="has-text-white-bis">
                                    <small>Valores Referencia do Indicador</small>
                                </p>
                            </div>    
                        </nav>    
                    </header>
                    <div class="card-content">
                        <div class="table-container">                
                            <table class="table is-narrow is-hoverable is-fullwidth is-striped">
                                <thead>
                                    <tr>
                                        <th><small>Grupo</small></th>
                                        <th><small>Indicador</small></th>
                                        <th colspan="1" class="has-text-centered"><small>Ações</small></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="i in indicadores" :key="i.id">
                                        <td><small>{{i.grupo_indicador.descricao}}</small></td>
                                        <td><small>{{i.descricao}}</small></td>
                                        <td class="has-text-centered"><small><button class="button is-ghost is-small is-inverted is-link" @click="callValRef(i)"><span class="fas fa-th-list"></span></button></small></td>
                                    </tr>
                                </tbody>    
                            </table>
                        </div>   
                    </div>
                </div> 
            </div>

            <div class="column" v-show="this.mostraValoresReferencia">
                <div class="card">
                    <header class="panel-heading has-background-grey">
                        <nav class="level">
                            <div class="level-left">
                                <p class="has-text-white-bis">
                                    <small>Valores Referencia do Indicador {{ this.indicadorSelecionado.descricao }}</small>
                                </p>
                            </div>    
                            <div class="level-right">
                                <small><a v-on:click="mostraValoresReferencia = false"><span class="fa fa-times has-text-black has-text-white-bis"></span></a></small>
                            </div>
                        </nav>    
                    </header>

                    <div class="card-content">
                        <div class="table-container">                
                            <table class="table is-narrow is-fullwidth is-striped is-hoverable">
                                <thead>
                                    <tr>
                                        <th class="has-text-left"><small>Status</small></th>
                                        <th class="has-text-left"><small>Faixa Inicial</small></th>
                                        <th class="has-text-left"><small>Faixa Final</small></th>
                                        <th colspan="2" class="has-text-centered"><small>Ação</small></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(vri, index) in valoresReferenciasIndicadores" :key="vri.id">
                                        <td class="has-text-left has-background-danger-dark has-text-white-bis" v-if="vri.status == '1'"><small>Péssimo</small></td>
                                        <td class="has-text-left has-background-danger-light" v-if="vri.status == '2'"><small>Ruim</small></td>
                                        <td class="has-text-left has-background-warning-light" v-if="vri.status == '3'"><small>Normal</small></td>
                                        <td class="has-text-left has-background-success-light" v-if="vri.status == '4'"><small>Bom</small></td>
                                        <td class="has-text-left has-background-success-dark has-text-white-bis" v-if="vri.status == '5'"><small>Excelente</small></td>

                                        <td class="has-text-left"><small>{{vri.faixa_inicial}}</small></td>
                                        <td class="has-text-left"><small>{{vri.faixa_final}}</small></td>
                                        <td class="has-text-centered"><small><button class="button is-ghost is-small is-inverted is-link" @click="edit(index)"><span class="fas fa-edit"></span></button></small></td>
                                        <td class="has-text-centered"><small><button class="button is-ghost is-small is-inverted is-link" @click="erase(index)"><span class="fas fa-trash-alt"></span></button></small></td>
                                    </tr>
                                </tbody>    
                            </table>
                        </div>    
                    </div>
                </div>
            </div>
        </div>   

    </div>        

</template>

<script>
    import {http} from '../../services/config'

    export default {
        name: "ValorReferenciaIndicador",

        data(){
            return{
                valorReferenciaIndicador: {
                    id: null,
                    faixa_inicial: null,
                    faixa_final: null,
                    status: null,
                    index: null
                },
                gruposIndicadores: [],
                indicadores: [],
                valoresReferenciasIndicadores: [],
                indicadorSelecionado: [],
                cmbGrupoIndicador: 0,
                showInfo: false,
                mostraValoresReferencia: false,
                isShowModal: false
            }
        },

        methods: {
            add: function() {
                this.clearFields()
                this.isShowModal = true              
            },

            edit: function(index){
                this.valorReferenciaIndicador.id = this.valoresReferenciasIndicadores[index].id
                this.valorReferenciaIndicador.indicador = this.indicadorSelecionado.id
                this.valorReferenciaIndicador.status = this.valoresReferenciasIndicadores[index].status
                this.valorReferenciaIndicador.faixa_inicial = this.valoresReferenciasIndicadores[index].faixa_inicial
                this.valorReferenciaIndicador.faixa_final = this.valoresReferenciasIndicadores[index].faixa_final
                this.valorReferenciaIndicador.index = index
                this.isShowModal = true              
            },

            erase: function(index){
                if (window.confirm('Confirma exclusão?')) {
                    http.delete('/valorReferenciaIndicador/excluir/'+this.valoresReferenciasIndicadores[index].id).then(response =>{
                        this.valoresReferenciasIndicadores.splice(index, 1)
                        if (response.status == 204) {
                            alert('Registro excluído!')
                        }
                    }).catch(e => {
                        console.log(e);
                    })
                }
            },


            save: function(){
                var data

                if (this.valorReferenciaIndicador.status == null) {
                    alert('Status não selecionado!')
                    return
                }

                if (this.valorReferenciaIndicador.faixa_inicial == null || this.valorReferenciaIndicador.faixa_inicial == '' ) {
                    alert('Faixa inicial não inforamda!')
                    return
                }

                if (this.valorReferenciaIndicador.faixa_final == null || this.valorReferenciaIndicador.faixa_final == '' ) {
                    alert('Faixa final não inforamda!')
                    return
                }

                data = {id: this.valorReferenciaIndicador.id,
                    indicador: this.indicadorSelecionado.id,
                    status: this.valorReferenciaIndicador.status,
                    faixa_inicial: this.valorReferenciaIndicador.faixa_inicial,
                    faixa_final: this.valorReferenciaIndicador.faixa_final
                }

                if (this.valorReferenciaIndicador.id == null) {
                    http.post('/valorReferenciaIndicador/adicionar', data).then(response =>{
                        if (response.data.id != undefined && response.data.id != '') {
                            this.valorReferenciaIndicador.id = response.data.id
                            this.valoresReferenciasIndicadores.push(this.valorReferenciaIndicador)
                            this.clearFields()
                        } else {
                            alert(response.request.responseText)
                        }
                    }).catch(e => {
                        alert(e.response.data);
                        return
                    })
                } 
                else {
                    http.put('/valorReferenciaIndicador/editar/'+this.valorReferenciaIndicador.id, data).then(response =>{
                        this.valoresReferenciasIndicadores[this.valorReferenciaIndicador.index].status = response.data.status
                        this.valoresReferenciasIndicadores[this.valorReferenciaIndicador.index].faixa_inicial = response.data.faixa_inicial
                        this.valoresReferenciasIndicadores[this.valorReferenciaIndicador.index].faixa_final = response.data.faixa_final
                        this.valoresReferenciasIndicadores[this.valorReferenciaIndicador.index].indicador = this.indicadorSelecionado
                    }).catch(e => {
                        alert(e.response.data);
                        return
                    })
                }                    

            },

            clearFields: function() {
                this.valorReferenciaIndicador = {
                    id: null,
                    faixa_inicial: null,
                    faixa_final: null,
                    status: null,
                    index: null
                }
            },

            filterRecords: function(){
                http.get('indicador/listar/', {params: {
                    idGrupoIndicador: this.cmbGrupoIndicador
                }}).then(response => {
                    this.indicadores = response.data.results
                    if (this.indicadores.length == 0) {
                        this.showInfo = false
                        alert('Não há indicadores associados aos parâmetros selecionados!')
                    } else {
                        this.showInfo = true
                    }
                })
            },

            callValRef: function(ind) {
                this.mostraValoresReferencia = true
                this.indicadorSelecionado = ind
                http.get('valorReferenciaIndicador/listar/', {params: {
                    idIndicador: this.indicadorSelecionado.id,
                }}).then(response => {
                    this.valoresReferenciasIndicadores = response.data.results
                })
            }

        },

        mounted: function() {
        },
        
        created: function() {
            http.get('grupoIndicador/listar/').then(response => {
                this.gruposIndicadores = response.data.results
            })

        }

    }
</script>

<style scoped>
</style>