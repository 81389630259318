<template>
    <div>
        <button class="button is-link is-light is-pulled-right is-small" v-on:click="add()">
            <strong>Cadastrar</strong>
        </button>  
        <br>
        <br>

        <div class="modal" v-bind:class="{ 'is-active': isShowModal }">
            <div class="modal-background" v-on:click="isShowModal = false"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                <p class="modal-card-title">Cadastrar</p>
                <button class="delete" aria-label="close" v-on:click="isShowModal = false"></button>
                </header>
                <section class="modal-card-body">
                    <form v-if="!this.mostraBPVersaoConfig && !this.mostraCompartilhamento">
                        <div class="field is-horizontal">
                            <div class="field-label is-normal">
                                <label class="label"><small>Descrição</small></label>
                            </div>                            
                            <div class="field-body">
                                <div class="field is-normal">
                                    <input class="input" type="text" placeholder="Informe a descrição do balanco patrimonial" v-model="balancoPatrimonialVersao.descricao">
                                </div>
                            </div>
                        </div>

                        <div class="field is-horizontal">
                            <div class="field-label is-normal">
                                <label class="label"><small>Nome da Fórmula do Ativo</small></label>
                            </div>                            
                            <div class="field-body">
                                <div class="field is-normal">
                                    <input class="input" type="text" placeholder="Informe o nome da fórmula do ativo" v-model="balancoPatrimonialVersao.nome_formula_ativo">
                                </div>
                            </div>
                        </div>

                        <div class="field is-horizontal">
                            <div class="field-label is-normal">
                                <label class="label"><small>Nome da Fórmula do Passivo</small></label>
                            </div>                            
                            <div class="field-body">
                                <div class="field is-normal">
                                    <input class="input" type="text" placeholder="Informe o nome da fórmula do passivo" v-model="balancoPatrimonialVersao.nome_formula_passivo">
                                </div>
                            </div>
                        </div>

                        <div class="field is-horizontal">
                            <div class="field-label is-normal">
                                <label class="label"><small>Comentários</small></label>
                            </div>                            
                            <div class="field-body">
                                <div class="field is-normal">
                                    <textarea class="textarea" placeholder="Comente sobre esse balanço patrimonial" v-model="balancoPatrimonialVersao.comentario">
                                    </textarea>
                                </div>
                            </div>
                        </div>
                    </form>  

                    <form v-if="this.mostraCompartilhamento">
                        <div class="field is-horizontal">
                            <div class="field-label is-normal">
                                <label class="label"><small>Versão</small></label>
                            </div>                            
                            <div class="field-body">
                                <div class="field">
                                    <div class="control">
                                        <div class="select is-fullwidth">
                                        <select v-model="balancoPatrimonialCompartilhar.versao_plano_contas">
                                            <option v-for="versao_plano_contas in versoesPlanosContas" :key="versao_plano_contas.id" :value="versao_plano_contas">
                                                {{versao_plano_contas.descricao}}
                                            </option>
                                        </select>
                                        </div>    
                                    </div>
                                </div>  
                            </div>
                        </div>
                    </form>  

                    <form v-if="this.mostraBPVersaoConfig">
                        <div class="field is-horizontal">
                            <div class="field-label is-normal">
                                <label class="label"><small>Destino</small></label>
                            </div>                            

                            <div class="field-body">
                                <div class="field">
                                    <select class="select is-fullwidth" v-model="balancoPatrimonialVersaoConfig.destino">
                                        <option value="A">Ativo</option>
                                        <option value="P">Passivo</option>
                                    </select>
                                </div>   
                            </div>    
                        </div>

                        <div class="field is-horizontal">
                            <div class="field-label is-normal">
                                <label class="label"><small>Descrição</small></label>
                            </div>                            
                            <div class="field-body">
                                <div class="field is-normal">
                                    <input class="input" type="text" placeholder="Informe a descrição da versão do plano de contas" v-model="balancoPatrimonialVersaoConfig.descricao">
                                </div>
                            </div>
                        </div>

                        <div class="field is-horizontal">
                            <div class="field-label is-normal">
                                <label class="label"><small>Sequencial</small></label>
                            </div>                            
                            <div class="field-body">
                                <div class="field is-normal">
                                    <input class="input" type="text" v-model="balancoPatrimonialVersaoConfig.sequencial">
                                </div>
                            </div>
                        </div>

                        <div class="field is-horizontal">
                            <div class="field-label">
                                <label class="label"><small>Negrito</small></label>
                            </div>                            

                            <div class="field-body">
                                <div class="field is-normal">
                                    <select class="select is-fullwidth" v-model="balancoPatrimonialVersaoConfig.eNegrito">
                                        <option value="S">Sim</option>
                                        <option value="N">Não</option>
                                    </select>
                                </div>
                            </div>    
                        </div>   
                        
                        <div class="field is-horizontal">
                            <div class="field-label is-normal">
                                <label class="label"><small>Desejada</small></label>
                            </div>                            

                            <div class="field-body">
                                <div class="field">
                                    <select class="select is-fullwidth" v-model="balancoPatrimonialVersaoConfig.tipo_evolucao_desejada">
                                        <option value="A">Alta</option>
                                        <option value="B">Baixa</option>
                                    </select>
                                </div>   
                            </div>    
                        </div>

                        <div class="field is-horizontal">
                            <div class="field-label is-normal">
                                <label class="label"><small>Referência AV</small></label>
                            </div>                            

                            <div class="field-body">
                                <div class="field">
                                    <select class="select is-fullwidth" v-model="balancoPatrimonialVersaoConfig.referencia_av">
                                        <option value="S">Sim</option>
                                        <option value="N">Não</option>
                                    </select>
                                </div>   
                            </div>    
                        </div>

                        <div class="field is-horizontal">
                            <div class="field-label is-normal">
                                <label class="label"><small>Análise Dinâmica</small></label>
                            </div>                            

                            <div class="field-body">
                                <div class="field">
                                    <select class="select is-fullwidth" v-model="balancoPatrimonialVersaoConfig.tipo_grupo_analise_dinamica">
                                        <option value="NE">Nenhum</option>
                                        <option value="CF">Circulante Financeiro</option>
                                        <option value="CO">Circulante Operacional</option>
                                        <option value="NC">Não Circulante</option>
                                    </select>
                                </div>   
                            </div>    
                        </div>


                        <div class="field is-horizontal">
                            <div class="field-label is-normal">
                                <label class="label"><small>Fórmula</small></label>
                            </div>                            
                            <div class="field-body">
                                <div class="field is-normal">
                                    <textarea class="textarea" placeholder="Informe a fórmula para preenchimento" v-model="balancoPatrimonialVersaoConfig.formula">
                                    </textarea>
                                </div>
                            </div>
                        </div>
                    </form>  
                </section>
                <footer class="modal-card-foot">
                <button class="button is-success" @click="save()">Salvar</button>
                <button class="button" v-on:click="isShowModal = false">Cancelar</button>
                </footer>
            </div>
        </div>        

        <div class="columns">
            <div class="column is-two-fifths">
                <div class="card">
                    <header class="panel-heading has-background-link-dark has-text-light">
                        <nav class="level">
                            <div class="level-left">
                                <p class="has-text-white-bis">
                                    <small>Balanços Patrimoniais</small>
                                </p>
                            </div>    
                        </nav>    
                    </header>
                    <div class="card-content">
                        <div class="table-container">                
                            <table class="table is-striped is-narrow is-hoverable is-fullwidth">
                                <thead>
                                    <tr>
                                        <th><small>Descrição</small></th>
                                        <th><small>Comentários</small></th>
                                        <th colspan="4" class="has-text-centered"><small>Ações</small></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(bp, index) in balancosPatrimoniais" :key="bp.id">
                                        <td><small>{{bp.descricao}}</small></td>
                                        <td><small>{{bp.comentario}}</small></td>
                                        <td class="has-text-centered" style="width:15px"><small><button class="button is-ghost is-small is-inverted is-link" @click="edit(index)" :disabled="mostraCompartilhamento || mostraBPVersaoConfig"><span class="fas fa-edit"></span></button></small></td>
                                        <td class="has-text-centered" style="width:15px"><small><button class="button is-ghost is-small is-inverted is-link" @click="erase(index)" :disabled="mostraCompartilhamento || mostraBPVersaoConfig"><span class="fas fa-trash-alt"></span></button></small></td>
                                        <td class="has-text-centered" style="width:15px"><button class="button is-ghost is-small is-inverted is-link has-tooltip-left" data-tooltip="Compartilhar" @click="callCompartilhamento(bp)" :disabled="mostraCompartilhamento || mostraBPVersaoConfig"><span class="fas fa-share-alt"></span></button></td>
                                        <td class="has-text-centered" style="width:15px"><small><button class="button is-ghost is-small is-inverted is-link" @click="callBPConfig(bp)" :disabled="mostraCompartilhamento || mostraBPVersaoConfig"><span class="fas fa-th-list"></span></button></small></td>
                                    </tr>
                                </tbody>    
                            </table>
                        </div>  
                    </div>      
                </div>
            </div>

            <div class="column" v-show="this.mostraCompartilhamento">
                <div class="card">
                    <header class="panel-heading has-background-grey">
                        <nav class="level">
                            <div class="level-left">
                                <p class="has-text-white-bis">
                                    <small>Compartilhar {{ this.bpVersaoSelecionado.descricao }}</small>
                                </p>
                            </div>    
                            <div class="level-right">
                                <small><a v-on:click="mostraCompartilhamento = false"><span class="fa fa-times has-text-black has-text-white-bis"></span></a></small>
                            </div>
                        </nav>    
                    </header>

                    <div class="card-content">
                        <div class="table-container">                
                            <table class="table is-narrow is-fullwidth is-striped is-hoverable">
                                <thead>
                                    <tr>
                                        <th class="has-text-left"><small>Versão</small></th>
                                        <th colspan="2" class="has-text-centered"><small>Ação</small></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(bpVersao, index) in balancoPatrimonialVersoes" :key="bpVersao.id">
                                        <td class="has-text-left"><small>{{bpVersao.versao_plano_contas.descricao}}</small></td>
                                        <td class="has-text-centered"><small><button class="button is-ghost is-small is-inverted is-link" @click="erase(index)"><span class="fas fa-trash-alt"></span></button></small></td>
                                    </tr>
                                </tbody>    
                            </table>
                        </div>    
                    </div>
                </div>
            </div>

            <div class="column" v-show="this.mostraBPVersaoConfig">
                <div class="card">
                    <header class="panel-heading has-background-grey">
                        <nav class="level">
                            <div class="level-left">
                                <p class="has-text-white-bis">
                                    <small>Linhas do Balanço Patrimonial {{ this.bpVersaoSelecionado.descricao }}</small>
                                </p>
                            </div>    
                            <div class="level-right">
                                <small><a v-on:click="mostraBPVersaoConfig = false"><span class="fa fa-times has-text-black has-text-white-bis"></span></a></small>
                            </div>
                        </nav>    
                    </header>

                    <div class="card-content">
                        <div class="table-container">                
                            <table class="table is-narrow is-fullwidth is-striped is-hoverable">
                                <thead>
                                    <tr>
                                        <th class="has-text-left"><small>Sequencial</small></th>
                                        <th class="has-text-left"><small>Quadro</small></th>
                                        <th class="has-text-left"><small>Descrição</small></th>
                                        <th colspan="2" class="has-text-centered"><small>Ação</small></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(linha, index) in linhasBalancoPatrimonial" :key="linha.id">
                                        <td class="has-text-left"><small>{{linha.sequencial}}</small></td>
                                        <td class="has-text-left" v-if="linha.destino=='A'"><small>ATIVO</small></td>
                                        <td class="has-text-left" v-if="linha.destino=='P'"><small>PASSIVO</small></td>
                                        <td class="has-text-left"><small>{{linha.descricao}}</small></td>
                                        <td class="has-text-centered"><small><button class="button is-ghost is-small is-inverted is-link" @click="edit(index)"><span class="fas fa-edit"></span></button></small></td>
                                        <td class="has-text-centered"><small><button class="button is-ghost is-small is-inverted is-link" @click="erase(index)"><span class="fas fa-trash-alt"></span></button></small></td>
                                    </tr>
                                </tbody>    
                            </table>
                        </div>    
                    </div>
                </div>
            </div>
        </div>   
    </div>        

</template>

<script>
    // import _ from 'lodash'
    import {http} from '../../services/config'

    export default {
        name: "BalancoPatrimonialVersao",

        data(){
            return{
                balancoPatrimonialVersao: {
                    id: null,
                    descricao: null,
                    comentario: null,
                    versaoPlanoContas: null,
                    nome_formula_ativo: null,
                    nome_formula_passivo: null
                },
                balancoPatrimonialCompartilhar: { id: null,
                    balanco_patrimonial_versao: null,
                    versao_plano_contas: null
                },
                balancoPatrimonialVersaoConfig: {
                    id: null,
                    sequencial: null,
                    descricao: null,
                    eNegrito: "N",
                    indentacao: 0,
                    destino: "A",
                    formula: null,
                    tipo_evolucao_desejada: "A",
                    referencia_av: "N",
                    tipo_grupo_analise_dinamica: "NE"
                },
                versoesPlanoContas: [],
                balancosPatrimoniais: [],
                linhasBalancoPatrimonial: [],
                balancoPatrimonialVersoes: [],
                isShowModal: false,
                mostraBPVersaoConfig: false, 
                mostraCompartilhamento: false,
                bpVersaoSelecionado: {
                    id: null,
                    descricao: null
                },
                previous: null,
                next: null,
                page: null,
                numpages: null
            }
        },

        methods: {
            add: function() {
                this.clearFields()
                this.isShowModal = true              
            },

            edit: function(index){
                if (!this.mostraBPVersaoConfig && !this.mostraCompartilhamento) {
                    this.balancoPatrimonialVersao.id = this.balancosPatrimoniais[index].id
                    this.balancoPatrimonialVersao.descricao = this.balancosPatrimoniais[index].descricao
                    this.balancoPatrimonialVersao.nome_formula_ativo = this.balancosPatrimoniais[index].nome_formula_ativo
                    this.balancoPatrimonialVersao.nome_formula_passivo = this.balancosPatrimoniais[index].nome_formula_passivo
                    this.balancoPatrimonialVersao.comentario = this.balancosPatrimoniais[index].comentario
                    this.balancoPatrimonialVersao.index = index
                }
                else {
                    this.balancoPatrimonialVersaoConfig.id = this.linhasBalancoPatrimonial[index].id
                    this.balancoPatrimonialVersaoConfig.descricao = this.linhasBalancoPatrimonial[index].descricao
                    this.balancoPatrimonialVersaoConfig.sequencial = this.linhasBalancoPatrimonial[index].sequencial

                    if (this.linhasBalancoPatrimonial[index].e_negrito) {
                        this.balancoPatrimonialVersaoConfig.eNegrito = "S"
                    } else {
                        this.balancoPatrimonialVersaoConfig.eNegrito = "N"
                    }

                    this.balancoPatrimonialVersaoConfig.indentacao = this.linhasBalancoPatrimonial[index].indentacao
                    this.balancoPatrimonialVersaoConfig.formula = this.linhasBalancoPatrimonial[index].formula
                    this.balancoPatrimonialVersaoConfig.balancoPatrimonialVersao = this.bpVersaoSelecionado
                    this.balancoPatrimonialVersaoConfig.destino = this.linhasBalancoPatrimonial[index].destino
                    this.balancoPatrimonialVersaoConfig.tipo_evolucao_desejada = this.linhasBalancoPatrimonial[index].tipo_evolucao_desejada
                    this.balancoPatrimonialVersaoConfig.referencia_av = this.linhasBalancoPatrimonial[index].referencia_av
                    this.balancoPatrimonialVersaoConfig.tipo_grupo_analise_dinamica = this.linhasBalancoPatrimonial[index].tipo_grupo_analise_dinamica
                    this.balancoPatrimonialVersaoConfig.index = index
                }
                this.isShowModal = true
            },

            erase: function(index){
                if (window.confirm('Confirma exclusão?')) {
                    if (!this.mostraBPVersaoConfig && !this.mostraCompartilhamento) {
                        http.delete('/balancoPatrimonialVersao/excluir/'+this.balancosPatrimoniais[index].id).then(response =>{
                            this.balancosPatrimoniais.splice(index, 1)
                            if (response.status == 204) {
                                alert('Registro excluído!')
                            }
                        }).catch(e => {
                            console.log(e);
                        })
                    } else if (this.mostraCompartilhamento) {
                        http.delete('/balancoPatrimonialVersao/excluirVersoes/'+this.balancoPatrimonialVersoes[index].id).then(response =>{
                            this.balancoPatrimonialVersoes.splice(index, 1)
                            if (response.status == 204) {
                                alert('Registro excluído!')
                            }
                        }).catch(e => {
                            console.log(e);
                        })
                    }
                    else {
                        http.delete('/balancoPatrimonialVersaoConfig/excluir/'+this.linhasBalancoPatrimonial[index].id).then(response =>{
                            this.linhasBalancoPatrimonial.splice(index, 1)
                            if (response.status == 204) {
                                alert('Registro excluído!')
                            }
                        }).catch(e => {
                            console.log(e);
                        })
                    }
                }
            },

            callCompartilhamento: function(bpVersao) {
                this.mostraCompartilhamento = true
                this.bpVersaoSelecionado = bpVersao
                http.get('balancoPatrimonialVersao/listarVersoes/', {params: {
                    idBalancoPatrimonialVersao: this.bpVersaoSelecionado.id
                }}).then(response => {
                    this.balancoPatrimonialVersoes = response.data.results
                })
            },

            clearFields: function() {
                if (!this.mostraBPVersaoConfig && !this.mostraCompartilhamento) {
                    this.balancoPatrimonialVersao = { id: null,
                    descricao: null,
                    comentario: null,
                    nome_formula_ativo: null,
                    nome_formula_passivo: null,
                    index: null }
                } else if (this.mostraCompartilhamento) {
                    this.balancoPatrimonialCompartilhar = {id: null,
                        balanco_patrimonial_versao: null,
                        versao_plano_contas: null }
                } else {
                    this.balancoPatrimonialVersaoConfig = {
                        id: null,
                        sequencial: null,
                        descricao: null,
                        eNegrito: "N",
                        indentacao: 0,
                        formula: null,
                        balancoPatrimonialVersao: this.bpVersaoSelecionado,
                        destino: "A",
                        tipo_evolucao_desejada: "A",
                        referencia_av: "N",
                        tipo_grupo_analise_dinamica: "NE",
                        index: null
                    }
                }
            },

            callBPConfig: function(bpVersao) {
                this.mostraBPVersaoConfig = true
                this.bpVersaoSelecionado = bpVersao
                http.get('balancoPatrimonialVersaoConfig/listar/', {params: {
                    idBalancoPatrimonialVersao: this.bpVersaoSelecionado.id
                }}).then(response => {
                    this.linhasBalancoPatrimonial = response.data.results
                })

            },

            save: function(){
                var data

                if (!this.mostraBPVersaoConfig && !this.mostraCompartilhamento) {

                    if (this.balancoPatrimonialVersao.descricao == null) {
                        alert('Descrição do balanço patrimonial não preenchida!')
                        return
                    }

                    if (this.balancoPatrimonialVersao.nome_formula_ativo == null || this.balancoPatrimonialVersao.nome_formula_ativo == "") {
                        alert('Nome da fórmula do ativo não preenchida!')
                        return
                    }

                    if (this.balancoPatrimonialVersao.nome_formula_passivo == null || this.balancoPatrimonialVersao.nome_formula_passivo == '') {
                        alert('Nome da fórmula do passivo não preenchida!')
                        return
                    }
                    data = {id: this.balancoPatrimonialVersao.id,
                        usuario: this.$store.state.idUser,
                        descricao: this.balancoPatrimonialVersao.descricao,
                        comentario: this.balancoPatrimonialVersao.comentario,
                        nome_formula_ativo: this.balancoPatrimonialVersao.nome_formula_ativo,
                        nome_formula_passivo: this.balancoPatrimonialVersao.nome_formula_passivo
                    }

                    let formData = new FormData()

                    formData.append('id', data.id)
                    formData.append('usuario', data.usuario)
                    formData.append('descricao', data.descricao)
                    formData.append('nome_formula_ativo', data.nome_formula_ativo)
                    formData.append('nome_formula_passivo', data.nome_formula_passivo)
                    formData.append('comentario', data.comentario)

                    if (this.balancoPatrimonialVersao.id == null) {
                        http.post('/balancoPatrimonialVersao/adicionar', formData).then(response =>{
                            if (response.data.id != undefined && response.data.id != '') {
                                this.balancoPatrimonialVersao.id = response.data.id
                                this.balancosPatrimoniais.push(this.balancoPatrimonialVersao)
                                this.clearFields()
                            } else {
                                alert(response.request.responseText)
                            }
                        }).catch(e => {
                            alert(e.response.data);
                            return
                        })

                    } else {
                        http.put('/balancoPatrimonialVersao/editar/'+this.balancoPatrimonialVersao.id, formData).then(response =>{
                            this.balancosPatrimoniais[this.balancoPatrimonialVersao.index].nome_formula_ativo = this.balancoPatrimonialVersao.nome_formula_ativo
                            this.balancosPatrimoniais[this.balancoPatrimonialVersao.index].nome_formula_passivo = this.balancoPatrimonialVersao.nome_formula_passivo
                            this.balancosPatrimoniais[this.balancoPatrimonialVersao.index].descricao = response.data.descricao
                            this.balancosPatrimoniais[this.balancoPatrimonialVersao.index].comentario = this.balancoPatrimonialVersao.comentario
                        }).catch(e => {
                            alert(e.response.data);
                            return
                        })
                    }

                } else if (this.mostraCompartilhamento) {
                    if (this.balancoPatrimonialCompartilhar.versao_plano_contas == null) {
                        alert('Versão não informada!')
                        return
                    }

                    data = {id: this.balancoPatrimonialCompartilhar.id,
                        balanco_patrimonial_versao: this.bpVersaoSelecionado.id,
                        versao_plano_contas: this.balancoPatrimonialCompartilhar.versao_plano_contas.id
                    }

                    if (this.balancoPatrimonialCompartilhar.id == null) {
                        http.post('/balancoPatrimonialVersao/adicionarVersoes', data).then(response =>{
                            if (response.data.id != undefined && response.data.id != '') {
                                this.balancoPatrimonialCompartilhar.id = response.data.id
                                this.balancoPatrimonialVersoes.push(this.balancoPatrimonialCompartilhar)
                                this.clearFields()
                            } else {
                                alert(response.request.responseText)
                            }
                        }).catch(e => {
                            alert(e.response.data);
                            return
                        })
                    } 
                } else {
                    if (this.balancoPatrimonialVersaoConfig.descricao == null) {
                        alert('Descrição da linha não informada!')
                        return
                    }

                    if (this.balancoPatrimonialVersaoConfig.sequencial == null) {
                        alert('Sequencial da linha não informado!')
                        return
                    }

                    if (this.balancoPatrimonialVersaoConfig.formula == null) {
                        alert('Fórmula da linha não informada!')
                        return
                    }

                    data = {id: this.balancoPatrimonialVersaoConfig.id,
                        descricao: this.balancoPatrimonialVersaoConfig.descricao,
                        sequencial: this.balancoPatrimonialVersaoConfig.sequencial,
                        e_negrito: false,
                        indentacao: this.balancoPatrimonialVersaoConfig.indentacao,
                        formula: this.balancoPatrimonialVersaoConfig.formula,
                        destino: this.balancoPatrimonialVersaoConfig.destino,
                        tipo_evolucao_desejada: this.balancoPatrimonialVersaoConfig.tipo_evolucao_desejada,
                        referencia_av: this.balancoPatrimonialVersaoConfig.referencia_av,
                        balanco_patrimonial_versao: this.bpVersaoSelecionado.id,
                        tipo_grupo_analise_dinamica: this.balancoPatrimonialVersaoConfig.tipo_grupo_analise_dinamica
                    }

                    if (this.balancoPatrimonialVersaoConfig.eNegrito == 'S') (
                        data.e_negrito = true
                    )

                    if (this.balancoPatrimonialVersaoConfig.id == null) {
                        http.post('/balancoPatrimonialVersaoConfig/adicionar', data).then(response =>{
                            if (response.data.id != undefined && response.data.id != '') {
                                this.balancoPatrimonialVersaoConfig.id = response.data.id
                                this.linhasBalancoPatrimonial.push(this.balancoPatrimonialVersaoConfig)
                                this.clearFields()
                            } else {
                                alert(response.request.responseText)
                            }
                        }).catch(e => {
                            alert(e.response.data);
                            return
                        })
                    } 
                    else {
                        http.put('/balancoPatrimonialVersaoConfig/editar/'+this.balancoPatrimonialVersaoConfig.id, data).then(response =>{
                            this.linhasBalancoPatrimonial[this.balancoPatrimonialVersaoConfig.index].descricao = response.data.descricao
                            this.linhasBalancoPatrimonial[this.balancoPatrimonialVersaoConfig.index].sequencial = response.data.sequencial
                            this.linhasBalancoPatrimonial[this.balancoPatrimonialVersaoConfig.index].e_negrito = response.data.e_negrito
                            this.linhasBalancoPatrimonial[this.balancoPatrimonialVersaoConfig.index].indentacao = response.data.indentacao
                            this.linhasBalancoPatrimonial[this.balancoPatrimonialVersaoConfig.index].formula = response.data.formula
                            this.linhasBalancoPatrimonial[this.balancoPatrimonialVersaoConfig.index].destino = response.data.destino
                            this.linhasBalancoPatrimonial[this.balancoPatrimonialVersaoConfig.index].tipo_evolucao_desejada = response.data.tipo_evolucao_desejada
                            this.linhasBalancoPatrimonial[this.balancoPatrimonialVersaoConfig.index].referencia_av = response.data.referencia_av
                            this.linhasBalancoPatrimonial[this.balancoPatrimonialVersaoConfig.index].tipo_grupo_analise_dinamica = response.data.tipo_grupo_analise_dinamica
                            this.linhasBalancoPatrimonial[this.balancoPatrimonialVersaoConfig.index].balanco_patrimonial_versao = this.bpVersaoSelecionado
                        }).catch(e => {
                            alert(e.response.data);
                            return
                        })
                    }                    
                }

            },
        },

        mounted: function() {
        },
        
        created: function() {
            http.get('versoesPlanoContas/listar').then(response =>{
                    this.versoesPlanosContas = response.data.results
            })

            http.get('balancoPatrimonialVersao/listar/').then(response => {
                    this.balancosPatrimoniais = response.data.results
                    this.previous = response.data.previous
                    this.next = response.data.next
                    this.page = response.data.page
                    this.numpages = response.data.numpages
            })

        }

    }
</script>

<style scoped>
</style>