<template>
    <div>
        <br>
        <h5 class="title is-5">Extratos
            <button class="button is-link is-light is-pulled-right is-small" v-on:click="add()">
                Lançar
            </button>  
        </h5>

        <hr>
        
        <form>
            <div class="field is-horizontal">
                <div class="field-body">
                    <div class="field is-narrow">
                        <div class="control">
                            <div class="select is-fullwidth">
                                <select v-model="cmbCorretora">
                                    <option value="0">TODAS AS CORRETORAS</option>
                                    <option v-for="corretoraUsuario in corretorasUsuarios" :key="corretoraUsuario.id" :value="corretoraUsuario.id">
                                        {{corretoraUsuario.corretora.nomeCurto}}
                                    </option>
                                </select>
                            </div>    
                        </div>
                    </div>  

                    <div class="field is-narrow">
                        <div class="control">
                            <div class="select is-fullwidth">
                                <select v-model="cmbModelo">
                                    <option value="0">TODOS OS MODELOS</option>
                                    <option v-for="modeloCarteita in modelosCarteiras" :key="modeloCarteita.id" :value="modeloCarteita.id">
                                        {{modeloCarteita.descricao}}
                                    </option>
                                </select>
                            </div>    
                        </div>
                    </div>  

                    <div class="field is-narrow">
                        <div class="control">
                            <div class="select is-fullwidth">
                                <select v-model="cmbTipoMovimentacao">
                                    <option value="0">TODAS OS TIPOS DE MOVIMENTAÇÃO</option>
                                    <option value="1">Aplicação</option>
                                    <option value="2">Resgate</option>
                                    <option value="3">Negociação</option>
                                    <option value="4">Proventos</option>
                                    <option value="5">Transferência</option>
                                    <option value="6">Taxa de Administração</option>
                                    <option value="7">Tarifa</option>
                                    <option value="8">Emissão/Subscrição</option>
                                </select>
                            </div>    
                        </div>
                    </div>  

                    <div class="field">
                        <input class="input" type="date" placeholder="Informe a data inicial" v-model="dataInicial">
                    </div>

                    <div class="field">
                        <input class="input" type="date" placeholder="Informe a data final" v-model="dataFinal">
                    </div>

                    <div class="field">                    
                        <button type='submit' class="button is-link" @click.stop.prevent="filterRecords">Filtrar</button>
                    </div>    
                </div>
            </div>
        </form>  

        <br>

        <div class="columns">

            <div class="column">

                <div class="card">
                    <header class="card-header has-background-grey">
                        <p class="card-header-title has-text-white-bis">
                            Saldos por Corretora
                        </p>
                    </header>
                    <div class="card-content">
                        <div class="content">
                            <div class="table-container">                
                                <table class="table is-narrow is-hoverable is-fullwidth is-striped">
                                    <thead>
                                        <tr>
                                            <th><small>Corretora</small></th>
                                            <th class="has-text-right"><small>Saldo</small></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(saldoCorretora) in saldoCorretoras" :key="saldoCorretora.corretora">
                                            <td><small>{{saldoCorretora.corretora}}</small></td>
                                            <td class="has-text-right"><small>{{saldoCorretora.valorSaldo|numberFormat}}</small></td>
                                        </tr>
                                    </tbody>    
                                </table>
                            </div>    
                        </div>
                    </div>
                    <footer class="card-footer">
                    </footer>
                </div>

            </div>

            <div class="column is-one-quarter">

                <div class="card">
                    <header class="card-header has-background-primary">
                        <p class="card-header-title has-text-white-bis">
                            Saldo Geral
                        </p>
                    </header>
                    <div class="card-content has-background-primary-light">
                        <div class="content">
                        <h1 class="has-text-success-dark is-size-2 has-text-centered">{{saldoGeral}}</h1>
                        </div>
                    </div>
                </div>

            </div>

        </div> 

        <div class="modal" v-bind:class="{ 'is-active': isShowModal }">
            <div class="modal-background" v-on:click="isShowModal = false"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title">Lançar</p>
                    <button class="delete" aria-label="close" v-on:click="isShowModal = false"></button>
                </header>
                <section class="modal-card-body">
                    <form>
                        <div class="field is-horizontal">
                            <div class="field-label is-normal">
                                <label class="label"><small>Corretora</small></label>
                            </div>                            
                            <div class="field-body">
                                <div class="field">
                                    <div class="control">
                                        <div class="select is-fullwidth">
                                        <select v-model="corretoraExtrato.corretoraUsuario">
                                            <option v-for="corretoraUsuario in corretorasUsuarios" :key="corretoraUsuario.id" :value="corretoraUsuario">
                                                {{corretoraUsuario.corretora.nomeCurto}}
                                            </option>
                                        </select>
                                        </div>    
                                    </div>
                                </div>  
                            </div>
                        </div>

                        <div class="field is-horizontal">
                            <div class="field-label is-normal">
                                <label class="label"><small>Movimentação</small></label>
                            </div>                            
                            <div class="field-body">
                                <div class="field is-narrow">
                                    <input class="input" type="date" placeholder="Informe a data inicial" v-model="corretoraExtrato.dataMovimentacao">
                                </div>
                            </div>
                            <div class="field-label is-normal">
                                <label class="label"><small>Liquidação</small></label>
                            </div>                            
                            <div class="field-body">
                                <div class="field is-narrow">
                                    <input class="input" type="date" placeholder="Informe a data de término" v-model="corretoraExtrato.dataLiquidacao">
                                </div>
                            </div>
                        </div>

                        <div class="field is-horizontal">
                            <div class="field-label">
                                <label class="label"><small>Tipo de Movimentação</small></label>
                            </div>                            
                            <div class="field-body">
                                <div class="field is-narrow">
                                    <div class="control">
                                        <div class="select is-fullwidth">
                                            <select v-model="corretoraExtrato.tipoMovimentacao">
                                                <option value="1">Aplicação</option>
                                                <option value="2">Resgate</option>
                                                <option value="3">Negociação</option>
                                                <option value="4">Proventos</option>
                                                <option value="5">Transferência</option>
                                                <option value="6">Taxa de Administração</option>
                                                <option value="7">Tarifa</option>
                                                <option value="8">Emissão/Subscrição</option>
                                            </select>
                                        </div>    
                                    </div>
                                </div>  
                            </div>

                            <div class="field-label is-normal">
                                <label class="label"><small>Ativo</small></label>
                            </div>                            

                            <div class="field-body">
                                <div class="field">
                                    <div class="control">
                                        <div class="select is-fullwidth">
                                        <select v-model="corretoraExtrato.ativo" :disabled="this.corretoraExtrato.tipoMovimentacao != '4' && this.corretoraExtrato.tipoMovimentacao != '8'">
                                            <option v-for="ativo in ativos" :key="ativo.id" :value="ativo">
                                                {{ativo.descricao}}
                                            </option>
                                        </select>
                                        </div>    
                                    </div>
                                </div>  
                            </div>
                        </div>

                        <div class="field is-horizontal">
                            <div class="field-label is-normal">
                                <label class="label"><small>Modelo</small></label>
                            </div>                            
                            <div class="field-body">
                                <div class="field">
                                    <div class="control">
                                        <div class="select is-fullwidth">
                                        <select v-model="corretoraExtrato.modeloCarteira" :disabled="this.corretoraExtrato.tipoMovimentacao != '4' && this.corretoraExtrato.tipoMovimentacao != '8'">
                                            <option v-for="modeloCarteira in modelosCarteiras" :key="modeloCarteira.id" :value="modeloCarteira">
                                                {{modeloCarteira.descricao}}
                                            </option>
                                        </select>
                                        </div>    
                                    </div>
                                </div>  
                            </div>
                        </div>

                        <div class="field is-horizontal">
                            <div class="field-label is-normal">
                                <label class="label"><small>Valor</small></label>
                            </div>                            
                            <div class="field-body">
                                <div class="field is-narrow">
                                    <input class="input" type="number" placeholder="Informe o valor" v-model="corretoraExtrato.valor">
                                </div>
                            </div>
                        </div>

                        <div class="field is-horizontal">
                            <div class="field-label is-normal">
                                <label class="label"><small>Descrição</small></label>
                            </div>                            
                            <div class="field-body">
                                <div class="field is-fullwidth">
                                    <textarea  class="textarea" placeholder="Informe a descrição" v-model="corretoraExtrato.descricao"/>
                                </div>
                            </div>
                        </div>

                    </form>  
                </section>
                <footer class="modal-card-foot">
                <button class="button is-success" @click="save()">Salvar</button>
                <button class="button" v-on:click="isShowModal = false">Cancelar</button>
                </footer>
            </div>
        </div>   

        <br>     

        <div class="card" v-show="this.showInfo">
            <header class="card-header has-background-grey">
                <p class="card-header-title has-text-white-bis">
                    Lançamentos
                </p>
            </header>
            <div class="card-content">
                <div class="columns">
                    <div class="column">
                        <div class="table-container">                
                            <table class="table is-narrow is-hoverable is-fullwidth is-striped">
                                <thead>
                                    <tr>
                                        <th><small>Corretora</small></th>
                                        <th><small>Modelo</small></th>
                                        <th><small>Movimentação</small></th>
                                        <th><small>Liquidação</small></th>
                                        <th><small>Ativo</small></th>
                                        <th><small>Tipo de Movimentação</small></th>
                                        <th class="has-text-right"><small>Valor</small></th>
                                        <th><small>Descrição</small></th>
                                        <th colspan="3" class="has-text-center"><small>Ações</small></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(corretoraExtrato, index) in corretorasExtratos" :key="corretoraExtrato.id">
                                        <td><small>{{corretoraExtrato.corretoraUsuario.corretora.nomeCurto}}</small></td>

                                        <td v-if="corretoraExtrato.modeloCarteira == null"><small>{{corretoraExtrato.modeloCarteira}}</small></td>
                                        <td v-if="corretoraExtrato.modeloCarteira != null"><small>{{corretoraExtrato.modeloCarteira.descricao}}</small></td>

                                        <td><small>{{corretoraExtrato.dataMovimentacao|dateDDMMYYYY}}</small></td>
                                        <td><small>{{corretoraExtrato.dataLiquidacao|dateDDMMYYYY}}</small></td>

                                        <td v-if="corretoraExtrato.ativo == null"><small>{{corretoraExtrato.ativo}}</small></td>
                                        <td v-if="corretoraExtrato.ativo != null"><small>{{corretoraExtrato.ativo.codigo}}</small></td>

                                        <td v-if="corretoraExtrato.tipoMovimentacao=='1'"><small>Aplicação</small></td>
                                        <td v-if="corretoraExtrato.tipoMovimentacao=='2'"><small>Resgate</small></td>
                                        <td v-if="corretoraExtrato.tipoMovimentacao=='3'"><small>Negociação</small></td>
                                        <td v-if="corretoraExtrato.tipoMovimentacao=='4'"><small>Proventos</small></td>
                                        <td v-if="corretoraExtrato.tipoMovimentacao=='5'"><small>Transferência</small></td>
                                        <td v-if="corretoraExtrato.tipoMovimentacao=='6'"><small>Taxa de Administração</small></td>
                                        <td v-if="corretoraExtrato.tipoMovimentacao=='7'"><small>Tarifa</small></td>
                                        <td v-if="corretoraExtrato.tipoMovimentacao=='8'"><small>Emissão/Subscrição</small></td>

                                        <td class="has-text-right"><small>{{corretoraExtrato.valor|numberFormat}}</small></td>
                                        <td><small>{{corretoraExtrato.descricao}}</small></td>
                                        <td class="has-text-center"><small><a @click="edit(index)"><span class="fas fa-edit"></span></a></small></td>
                                        <td class="has-text-center"><small><a @click="erase(index)"><span class="fas fa-trash-alt"></span></a></small></td>
                                    </tr>
                                </tbody>    
                            </table>
                        </div>    

                        <nav class="pagination is-rounded is-small" role="navigation" aria-label="pagination">
                            <a class="pagination-previous button is-link" @click="previousPage()" :disabled="this.page == 1 || this.page == null">Anterior</a>
                            <a class="pagination-next button is-link" @click="nextPage()" :disabled="this.page == this.numpages">Próxima</a>                
                        </nav>
                        <br>
                    </div>

                </div>  
            </div>    
        </div>      

    </div>        

</template>

<script>
    import {http} from '../../services/config'
    import moment from 'moment'
    import _ from 'lodash'

    export default {
        name: "CorretoraExtrato",

        data(){
            return{
                corretoraExtrato: {
                    id: null,
                    dataMovimentacao: null,
                    dataLiquidacao: null,
                    tipoMovimentacao: null,
                    valor: null,
                    ativo: null,
                    corretoraUsuario: null,  
                    descricao: null,  
                    modeloCarteira: null,
                    index: null
                },
                corretorasUsuarios: [],
                corretorasExtratos: [],
                ativos: [],
                modelosCarteiras: [],
                saldoCorretoras: [],
                saldoGeral: null,
                cmbCorretora: "0",
                cmbModelo: "0",
                cmbTipoMovimentacao: "0",
                dataInicial: null,
                dataFinal: null,
                previous: null,
                next: null,
                page: null,
                numpages: null,
                isShowModal: false,
                showInfo: false
            }
        },

        methods: {
            clearFields: function() {
                this.corretoraExtrato = { id: null,
                    dataMovimentacao: null,
                    dataLiquidacao: null,
                    tipoMovimentacao: null,
                    valor: null,
                    ativo: null,
                    corretoraUsuario: null,
                    descricao: null,
                    modeloCarteira: null,
                    index: null }
            },
            add: function() {
                this.clearFields()
                this.isShowModal = true              
            },

            edit: function(index){
                this.corretoraExtrato.id = this.corretorasExtratos[index].id
                this.corretoraExtrato.dataMovimentacao = this.corretorasExtratos[index].dataMovimentacao
                this.corretoraExtrato.dataLiquidacao = this.corretorasExtratos[index].dataLiquidacao
                this.corretoraExtrato.tipoMovimentacao = this.corretorasExtratos[index].tipoMovimentacao
                this.corretoraExtrato.valor = this.corretorasExtratos[index].valor
                this.corretoraExtrato.ativo = this.corretorasExtratos[index].ativo
                this.corretoraExtrato.corretoraUsuario = this.corretorasExtratos[index].corretoraUsuario
                this.corretoraExtrato.descricao = this.corretorasExtratos[index].descricao
                this.corretoraExtrato.modeloCarteira = this.corretorasExtratos[index].modeloCarteira
                this.corretoraExtrato.index = index
                this.isShowModal = true    
            },

            erase: function(index){
                if (window.confirm('Confirma exclusão?')) {
                    http.delete('/corretorasExtratos/excluir/'+this.corretorasExtratos[index].id).then(response =>{
                        this.corretorasExtratos.splice(index, 1)
                        if (response.status == 204) {
                            this.atualizarResumoPorCorretura()                            
                            alert('Registro excluído!')
                        }
                    }).catch(e => {
                        console.log(e);
                    })
                }
            },

            save: function(){

                if (this.corretoraExtrato.corretoraUsuario == null) {
                    alert('Corretora não informada!')
                    return
                }

                if (this.corretoraExtrato.dataMovimentacao == null) {
                    alert('Data de movimentação não informada!')
                    return
                }

                if (this.corretoraExtrato.dataLiquidacao == null) {
                    alert('Data de liquidação não informada!')
                    return
                }

                if (this.corretoraExtrato.tipoMovimentacao == null) {
                    alert('Tipo de movimentação não informada!')
                    return
                }

                if ((this.corretoraExtrato.tipoMovimentacao == '4' || this.corretoraExtrato.tipoMovimentacao == '8') && this.corretoraExtrato.ativo == null) {
                    alert('É necessário informar o ativo referente a este provento/emissão/subscrição!')
                    return
                }

                if ((this.corretoraExtrato.tipoMovimentacao == '4' || this.corretoraExtrato.tipoMovimentacao == '8') && this.corretoraExtrato.modeloCarteira == null) {
                    alert('É necessário informar o modelo referente a este provento/emissão/subscrição!')
                    return
                }

                if (this.corretoraExtrato.valor == null || this.corretoraExtrato.valor == 0) {
                    alert('Valor nulo ou zero não são permitidos!')
                    return
                }

                if (this.corretoraExtrato.descricao == null || this.corretoraExtrato.descricao == '' ) {
                    alert('Descrição não informada!')
                    return
                }

                var data = {id: this.corretoraExtrato.id,
                    dataMovimentacao: moment(this.corretoraExtrato.dataMovimentacao).format('DD/MM/YYYY'),
                    dataLiquidacao: moment(this.corretoraExtrato.dataLiquidacao).format('DD/MM/YYYY'),
                    tipoMovimentacao: this.corretoraExtrato.tipoMovimentacao,
                    valor: this.corretoraExtrato.valor,
                    ativo: null,
                    corretoraUsuario: this.corretoraExtrato.corretoraUsuario.id,
                    descricao: this.corretoraExtrato.descricao,
                    modeloCarteira: null
                }

                if (this.corretoraExtrato.modeloCarteira != null) {
                    data.modeloCarteira = this.corretoraExtrato.modeloCarteira.id
                }

                if (this.corretoraExtrato.ativo != null) {
                    data.ativo = this.corretoraExtrato.ativo.id
                }

                if (this.corretoraExtrato.id == null) {
                    http.post('/corretorasExtratos/adicionar', data).then(response =>{
                        if (response.data.id != undefined && response.data.id != '') {
                            this.corretoraExtrato.id = response.data.id
                            this.corretorasExtratos.push(this.corretoraExtrato)
                            this.showInfo = true
                            this.clearFields()
                            this.atualizarResumoPorCorretura()
                        } else {
                            alert(response.request.responseText)
                        }
                    }).catch(e => {
                        console.log(e);
                    })

                } else {
                    http.put('/corretorasExtratos/editar/'+this.corretoraExtrato.id, data).then(response =>{
                        this.corretorasExtratos[this.corretoraExtrato.index].corretoraUsuario = this.corretoraExtrato.corretoraUsuario
                        this.corretorasExtratos[this.corretoraExtrato.index].dataMovimentacao = response.data.dataMovimentacao
                        this.corretorasExtratos[this.corretoraExtrato.index].dataLiquidacao = response.data.dataLiquidacao
                        this.corretorasExtratos[this.corretoraExtrato.index].tipoMovimentacao = response.data.tipoMovimentacao
                        this.corretorasExtratos[this.corretoraExtrato.index].ativo = this.corretoraExtrato.ativo
                        this.corretorasExtratos[this.corretoraExtrato.index].valor = response.data.valor
                        this.corretorasExtratos[this.corretoraExtrato.index].descricao = response.data.descricao
                        this.corretorasExtratos[this.corretoraExtrato.index].modeloCarteira = this.corretoraExtrato.modeloCarteira
                        this.atualizarResumoPorCorretura()
                    }).catch(e => {
                        console.log(e);
                    })
                }
            },

            callListAPI: function(nome) {
                http.get(nome, {params: {
                        cmbCorretora: this.cmbCorretora,
                        cmbTipoMovimentacao: this.cmbTipoMovimentacao,
                        dataInicial: this.dataInicial,
                        dataFinal: this.dataFinal,
                        cmbModelo: this.cmbModelo
                }}).then(response => {
                    this.corretorasExtratos = response.data.results
                    this.previous = response.data.previous
                    this.next = response.data.next
                    this.page = response.data.page
                    this.numpages = response.data.numpages

                    if (this.corretorasExtratos.length != 0) {
                        this.showInfo = true
                    } else {
                        this.showInfo = false
                        alert('Não há dados associados aos parâmetros informados!')
                    }

                })
            },

            nextPage: function(){
                if (this.page != this.numpages) {
                    this.callListAPI(this.next)
                }
            },
            previousPage: function(){
                if (this.page != 1 ) {
                    this.callListAPI(this.previous)
                }
            },
            filterRecords: function(){
                if (this.dataInicial != null && this.dataFinal == null) {
                    alert('Data final não preenchida!')
                    return;
                } else if (this.dataInicial == null && this.dataFinal != null) {
                    alert('Data inicial não preenchida!')
                    return;
                }
                this.callListAPI('corretorasExtratos/listar/')
            },
            atualizarResumoPorCorretura: function() {
                http.get('corretorasExtratos/saldoCorretoras/', {params: {
                    idPeriodo: null,
                }}).then(response =>{
                    this.saldoCorretoras = response.data.results
                    this.saldoGeral = _.sumBy(response.data.results, saldo => { return parseFloat(saldo.valorSaldo) } )
                    this.saldoGeral = this.saldoGeral.toFixed(2)
                })
            }
        },

        mounted: function() {
            http.get('corretorasUsuarios/listar').then(response =>{
                this.corretorasUsuarios = response.data
            })
            http.get('ativos/listar', {
                params: {
                    paginacao: 'N'
                }
            }).then(response =>{
                this.ativos = response.data.results
            })
            http.get('modelagemCarteira/modeloCarteiraListar').then(response =>{
                this.modelosCarteiras = response.data
            })
            this.atualizarResumoPorCorretura()
        },
        
        created: function() {
        }

    }
</script>

<style scoped>
</style>