<template>
    <div>
        <button class="button is-link is-light is-pulled-right is-small" v-on:click="add()">
            <strong>Cadastrar</strong>
        </button>  

        <br>
        <br>

        <div class="modal" v-bind:class="{ 'is-active': isShowModal }">
            <div class="modal-background" v-on:click="isShowModal = false"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title">Cadastrar</p>
                    <button class="delete" aria-label="close" v-on:click="isShowModal = false"></button>
                </header>
                <section class="modal-card-body">
                    <form v-if="!this.mostraGraficoIndicador">
                        <div class="field is-horizontal">
                            <div class="field-label is-normal">
                                <label class="label"><small>Descrição</small></label>
                            </div>                            
                            <div class="field-body">
                                <div class="field is-normal">
                                    <input class="input" type="text" placeholder="Informe a descrição" v-model="grafico.descricao">
                                </div>
                            </div>
                        </div>

                        <div class="field is-horizontal">
                            <div class="field-label is-normal">
                                <label class="label"><small>Tipo</small></label>
                            </div>                            

                            <div class="field-body">
                                <div class="field">
                                    <select class="select is-fullwidth" v-model="grafico.tipo">
                                        <option value="0" disabled>SELECIONE UM TIPO DE GRAFICO</option>
                                        <option value="1">Pizza</option>
                                        <option value="2">Barras</option>
                                        <option value="3">Linhas</option>
                                    </select>
                                </div>   
                            </div>    
                        </div>
                    </form>  

                    <form v-if="this.mostraGraficoIndicador">
                        <div class="field is-horizontal">
                            <div class="field-label is-normal">
                                <label class="label"><small>Grupo de Indicador</small></label>
                            </div>                            
                            <div class="field-body">
                                <div class="field">
                                    <div class="control">
                                        <div class="select is-fullwidth">
                                            <select v-model="cmbGrupoIndicador" @change="selectGrupoIndicador()">
                                                <option value="0">TODOS OS GRUPOS DE INDICADORES</option>
                                                <option v-for="gi in gruposIndicadores" :key="gi.id" :value="gi.id">
                                                    {{gi.descricao}}
                                                </option>
                                            </select>
                                        </div>    
                                    </div>
                                </div>  
                            </div>
                        </div>

                        <div class="field is-horizontal">
                            <div class="field-label is-normal">
                                <label class="label"><small>Indicador</small></label>
                            </div>                            
                            <div class="field-body">
                                <div class="field">
                                    <div class="control">
                                        <div class="select is-fullwidth">
                                            <select v-model="graficoIndicador.indicador">
                                                <option value="0" disabled>SELECIONE UM INDICADOR</option>
                                                <option v-for="indicador in indicadores" :key="indicador.id" :value="indicador">
                                                    {{indicador.descricao}}
                                                </option>
                                            </select>
                                        </div>    
                                    </div>
                                </div>  
                            </div>
                        </div>
                    </form>  

                </section>
                <footer class="modal-card-foot">
                <button class="button is-success" @click="save()">Salvar</button>
                <button class="button" v-on:click="isShowModal = false">Cancelar</button>
                </footer>
            </div>
        </div>        

        <div class="columns">
            <div class="column is-two-fifths">
                <div class="card">
                    <header class="panel-heading has-background-link-dark has-text-light">
                        <nav class="level">
                            <div class="level-left">
                                <p class="has-text-white-bis">
                                    <small>Gráficos</small>
                                </p>
                            </div>    
                        </nav>    
                    </header>
                    <div class="card-content">
                        <div class="table-container">                
                            <table class="table is-striped is-narrow is-hoverable is-fullwidth">
                                <thead>
                                    <tr>
                                        <th><small>Descrição</small></th>
                                        <th><small>Tipo</small></th>
                                        <th colspan="4" class="has-text-centered"><small>Ações</small></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(grafico, index) in graficos" :key="grafico.id">
                                        <td><small>{{grafico.descricao}}</small></td>
                                        <td v-if="grafico.tipo == '1'"><small>Pizza</small></td>
                                        <td v-if="grafico.tipo == '2'"><small>Barras</small></td>
                                        <td v-if="grafico.tipo == '3'"><small>Linhas</small></td>
                                        <td class="has-text-centered"><small><button class="button is-ghost is-small is-inverted is-link" data-tooltip="Editar" @click="edit(index)" :disabled="mostraGraficoIndicador"><span class="fas fa-edit"></span></button></small></td>
                                        <td class="has-text-centered"><small><button class="button is-ghost is-small is-inverted is-link" @click="erase(index)" :disabled="mostraGraficoIndicador"><span class="fas fa-trash-alt"></span></button></small></td>
                                        <td class="has-text-centered"><small><button class="button is-ghost is-small is-inverted is-link" @click="callGraficoIndicador(grafico)" :disabled="mostraGraficoIndicador"><span class="fas fa-th-list"></span></button></small></td>
                                    </tr>
                                </tbody>    
                            </table>
                        </div>    
                    </div>    
                </div>    
            </div>

            <div class="column" v-show="this.mostraGraficoIndicador">
                <div class="card">
                    <header class="panel-heading has-background-grey">
                        <nav class="level">
                            <div class="level-left">
                                <p class="has-text-white-bis">
                                    <small>Indicadores do Gráfico {{ this.graficoSelecionado.descricao }}</small>
                                </p>
                            </div>    
                            <div class="level-right">
                                <small><a v-on:click="mostraGraficoIndicador = false"><span class="fa fa-times has-text-black has-text-white-bis"></span></a></small>
                            </div>
                        </nav>    
                    </header>

                    <div class="card-content">
                        <div class="table-container">                
                            <table class="table is-narrow is-fullwidthtable is-striped is-narrow is-hoverable is-fullwidth">
                                <thead>
                                    <tr>
                                        <th class="has-text-left"><small>Indicador</small></th>
                                        <th colspan="1" class="has-text-centered"><small>Ação</small></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(gi, index) in graficoIndicadores" :key="gi.id">
                                        <td class="has-text-left"><small>{{gi.indicador.descricao}}</small></td>
                                        <td class="has-text-centered"><small><button class="button is-ghost is-small is-inverted is-link" @click="erase(index)"><span class="fas fa-trash-alt"></span></button></small></td>
                                    </tr>
                                </tbody>    
                            </table>
                        </div>    
                    </div>

                    <footer class="card-footer">
                        <a v-on:click="mostraGraficoIndicador = false" class="card-footer-item">Fechar</a>
                    </footer>            

                </div>
            </div>
        </div>   
    </div>        

</template>

<script>
    import {http} from '../../services/config'

    export default {
        name: "Grafico",

        data(){
            return{
                grafico: {
                    id: null,
                    descricao: null,
                    tipo: "0",
                    usuario: null
                },
                graficoIndicador: { id: null,
                    grafico: null,
                    indicador: "0"
                },
                graficos: [],
                graficoIndicadores: [],
                indicadores: [],
                cmbGrupoIndicador: "0",
                isShowModal: false,
                mostraGraficoIndicador: false,
                graficoSelecionado: {
                    id: null,
                    descricao: null
                },
                previous: null,
                next: null,
                page: null,
                numpages: null
            }
        },

        methods: {
            add: function() {
                this.clearFields()
                this.isShowModal = true   
                this.cmbGrupoIndicador = "0"
                http.get('indicador/listar/', {params: {
                    idGrupoIndicador: "0"
                }}).then(response => {
                    this.indicadores = response.data.results
                })
            },

            edit: function(index){
                if (!this.mostraGraficoIndicador) {
                    this.grafico.id = this.graficos[index].id
                    this.grafico.descricao = this.graficos[index].descricao
                    this.grafico.tipo = this.graficos[index].tipo
                    this.grafico.usuario = this.graficos[index].usuario
                    this.grafico.index = index
                }
                this.isShowModal = true
            },

            selectGrupoIndicador: function() {
                http.get('indicador/listar/', {params: {
                    idGrupoIndicador: this.cmbGrupoIndicador
                }}).then(response => {
                    this.indicadores = response.data.results
                })
            },

            erase: function(index){
                if (window.confirm('Confirma exclusão?')) {
                    if (!this.mostraGraficoIndicador) {
                        http.delete('/grafico/excluir/'+this.graficos[index].id).then(response =>{
                            this.graficos.splice(index, 1)
                            if (response.status == 204) {
                                alert('Registro excluído!')
                            }
                        }).catch(e => {
                            console.log(e);
                        })
                    } else if (this.mostraGraficoIndicador) {
                        http.delete('/graficoIndicador/excluir/'+this.graficoIndicadores[index].id).then(response =>{
                            this.graficoIndicadores.splice(index, 1)
                            if (response.status == 204) {
                                alert('Registro excluído!')
                            }
                        }).catch(e => {
                            console.log(e);
                        })
                    }
                }
            },

            callGraficoIndicador: function(grafico) {
                this.mostraGraficoIndicador = true
                this.graficoSelecionado = grafico
                http.get('graficoIndicador/listar/', {params: {
                    idGrafico: this.graficoSelecionado.id
                }}).then(response => {
                    this.graficoIndicadores = response.data.results
                })
            },

            clearFields: function() {
                if (!this.mostraGraficoIndicador) {
                    this.grafico = {id: null,
                        descricao: null,
                        tipo: "0",
                        usuario: null,
                        index: null 
                    }
                } else if (this.mostraGraficoIndicador) {
                    this.graficoIndicador = {id: null,
                        grafico: null,
                        indicador: "0" 
                    }
                }
            },

            save: function(){
                var data

                if (!this.mostraGraficoIndicador) {
                    if (this.grafico.descricao == null || this.grafico.descricao == '') {
                        alert('Descrição não preenchida!')
                        return
                    }

                    if (this.grafico.tipo == null || this.grafico.tipo == "0") {
                        alert('Tipo de gráfico não selecionado!')
                        return
                    }

                    data = {id: this.grafico.id,
                        descricao: this.grafico.descricao,
                        tipo: this.grafico.tipo,
                        usuario: this.$store.state.idUser
                    }

                    let formData = new FormData()

                    formData.append('id', data.id)
                    formData.append('usuario', data.usuario)
                    formData.append('descricao', data.descricao)
                    formData.append('tipo', data.tipo)

                    if (this.grafico.id == null) {
                        http.post('/grafico/adicionar', formData).then(response =>{
                            if (response.data.id != undefined && response.data.id != '') {
                                this.grafico.id = response.data.id
                                this.graficos.push(this.grafico)
                                this.clearFields()
                            } else {
                                alert(response.request.responseText)
                            }
                        }).catch(e => {
                            alert(e.response.data);
                            return
                        })
                    } 
                    else {
                        http.put('/grafico/editar/'+this.grafico.id, formData).then(response =>{
                            this.graficos[this.grafico.index].descricao = response.data.descricao
                            this.graficos[this.grafico.index].tipo = this.grafico.tipo
                        }).catch(e => {
                            alert(e.response.data);
                            return
                        })
                    }
                }
                 else if (this.mostraGraficoIndicador) {
                    if (this.graficoIndicador.indicador == null || this.graficoIndicador.indicador == "0") {
                        alert('Indicador não informado!')
                        return
                    }

                    data = {id: this.graficoIndicador.id,
                        grafico: this.graficoSelecionado.id,
                        indicador: this.graficoIndicador.indicador.id
                    }

                    if (this.graficoIndicador.id == null) {
                        http.post('/graficoIndicador/adicionar', data).then(response =>{
                            if (response.data.id != undefined && response.data.id != '') {
                                this.graficoIndicador.id = response.data.id
                                this.graficoIndicadores.push(this.graficoIndicador)
                                this.clearFields()
                            } else {
                                alert(response.request.responseText)
                            }
                        }).catch(e => {
                            alert(e.response.data);
                            return
                        })
                    } 
                }    
            }
        },

        mounted: function() {
        },
        
        created: function() {
            http.get('grafico/listar/').then(response => {
                this.graficos = response.data.results
            })

            http.get('grupoIndicador/listar/').then(response => {
                this.gruposIndicadores = response.data.results
            })

        }
    }
</script>

<style scoped>
</style>