<template>
    <div>
        <br>
        <h5 class="title is-5">Demonstração de Resultado
            <button class="button is-link is-light is-pulled-right is-small" v-on:click="processar()" :disabled="this.cmbPeriodoPerfil.estaFechado">
                Processar
            </button>  
        </h5>

        <form>
            <div class="field is-horizontal">
                <div class="field-body">

                    <div class="field is-narrow">
                        <div class="control">
                            <div class="select is-narrow">
                                <select v-model="cmbTipoPeriodo" @change="selectTipoPeriodo()">
                                    <option value="0" disabled>Selecione um tipo de período</option>
                                    <option v-for="td in tiposPeriodo" :key="td.id" :value="td.id">
                                        {{td.descricao}}
                                    </option>
                                </select>
                            </div>    
                        </div>
                    </div>  

                    <div class="field is-narrow">
                        <div class="control">
                            <div class="select is-fullwidth">
                                <select v-model="cmbPerfil" @change="selectPerfil()">
                                    <option value="0" disabled>Selecione um perfil</option>
                                    <option v-for="perfilUsuario in perfisUsuario" :key="perfilUsuario.id" :value="perfilUsuario.perfil.id">
                                        {{perfilUsuario.perfil.descricao}}
                                    </option>
                                </select>
                            </div>    
                        </div>
                    </div>  

                    <div class="field is-narrow">
                        <div class="control">
                            <div class="select is-narrow">
                                <select v-model="cmbPeriodoPerfil" @change="selectPeriodoPerfil()">
                                    <option value="0" disabled>Selecione um período</option>
                                    <option v-for="periodoPerfil in periodosPerfis" :key="periodoPerfil.id" :value="periodoPerfil">
                                        {{periodoPerfil.periodo.descricao}}
                                    </option>
                                </select>
                            </div>    
                        </div>
                    </div>  

                    <div class="field is-narrow">
                        <div class="control">
                            <div class="select is-narrow">
                                <select v-model="cmbDemonstracaoResultadoVersao">
                                    <option value="0" disabled>Selecione uma demonstração de resultado</option>
                                    <option v-for="dre in demonstracoesResultados" :key="dre.id" :value="dre.demonstracao_resultado_versao">
                                        {{dre.demonstracao_resultado_versao.descricao}}
                                    </option>
                                </select>
                            </div>    
                        </div>
                    </div>  

                    <div class="field">                    
                        <button type='submit' class="button is-link" @click.stop.prevent="filterRecords">Filtrar</button>
                    </div>    
                </div>

            </div>
        </form>  

        <hr>

        <div class="modal" v-bind:class="{ 'is-active': isProcessing }">
            <div class="modal-background"></div>
            <div class="modal-content">
                <progress class="progress is-large is-primary" max="100">50%</progress>
            </div>
        </div>        

        <div class="columns" v-show="this.showInfo">
            <div class="column">
                <div class="table-container">                
                    <table class="table is-fullwidth is-hoverable is-bordered">
                        <thead>
                            <tr>
                                <th class="has-text-left is-size-7"><small>DRE</small></th>
                                <th class="has-text-centered is-size-7"><small>{{anoMesReferencia|datePorTipo(cmbTipoPeriodo)}}</small></th>
                                <th class="has-text-centered is-size-7 has-text-grey-light">AV%</th>
                                <th class="has-text-centered is-size-7" v-if="cmbTipoPeriodo != '6' && cmbTipoPeriodo != '7'"><small>{{anoMesMesAnteriorReferencia|datePorTipo(cmbTipoPeriodo)}}</small></th>
                                <th class="has-text-centered is-size-7 has-text-grey-light"  v-if="cmbTipoPeriodo != '6' && cmbTipoPeriodo != '7'">AH%</th>
                                <th class="has-text-centered is-size-7"><small>{{anoMesAnoAnteriorReferencia|datePorTipo(cmbTipoPeriodo)}}</small></th>
                                <th class="has-text-centered is-size-7 has-text-grey-light">AH%</th>
                                <th class="has-text-centered is-size-7" v-if="cmbTipoPeriodo != '6' && cmbTipoPeriodo != '7'"><small>Acumulado Ano</small></th>
                                <th class="has-text-centered is-size-7 has-text-grey-light" v-if="cmbTipoPeriodo != '6' && cmbTipoPeriodo != '7'">AV%</th>
                                <th class="has-text-centered is-size-7" v-if="cmbTipoPeriodo != '6' && cmbTipoPeriodo != '7'"><small>Acum. Ano Anterior</small></th>
                                <th class="has-text-centered is-size-7 has-text-grey-light" v-if="cmbTipoPeriodo != '6' && cmbTipoPeriodo != '7'">AH%</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="dre in demonstracoesResultadosPeriodo" :key="dre.id">
                                <td class="is-size-7" v-if="dre.demonstracao_resultado_versao_config.e_negrito"><small><strong>{{dre.demonstracao_resultado_versao_config.descricao}}</strong></small></td>
                                <td class="is-size-7" v-if="!dre.demonstracao_resultado_versao_config.e_negrito"><small>{{dre.demonstracao_resultado_versao_config.descricao}}</small></td>
                                <td class="has-text-centered is-size-7"><small>{{dre.valor_periodo|numberFormat}}</small></td>
                                <td class="has-text-centered is-size-7 has-text-link has-text-weight-bold"><small>{{dre.perc_av|numberFormat}}</small></td>
                                
                                <td class="has-text-centered is-size-7" v-if="cmbTipoPeriodo != '6' && cmbTipoPeriodo != '7'"><small>{{dre.valorMesAnterior|numberFormat}}</small></td>
                                <td class="has-text-centered is-size-7 has-text-success has-text-weight-bold" v-if="dre.cor_ah_mes_anterior=='1' && cmbTipoPeriodo != '6' && cmbTipoPeriodo != '7'"><small>{{dre.perc_ah_mes_anterior|numberFormat}}</small></td>
                                <td class="has-text-centered is-size-7 has-text-danger has-text-weight-bold" v-if="dre.cor_ah_mes_anterior=='2' && cmbTipoPeriodo != '6' && cmbTipoPeriodo != '7'"><small>{{dre.perc_ah_mes_anterior|numberFormat}}</small></td>
                                <td class="has-text-centered is-size-7 has-text-info has-text-weight-bold" v-if="dre.cor_ah_mes_anterior=='3' && cmbTipoPeriodo != '6' && cmbTipoPeriodo != '7'"><small>{{dre.perc_ah_mes_anterior|numberFormat}}</small></td>

                                <td class="has-text-centered is-size-7"><small>{{dre.valorAnoAnterior|numberFormat}}</small></td>
                                <td class="has-text-centered is-size-7 has-text-success has-text-weight-bold" v-if="dre.cor_ah_ano_anterior=='1'"><small>{{dre.perc_ah_ano_anterior|numberFormat}}</small></td>
                                <td class="has-text-centered is-size-7 has-text-danger has-text-weight-bold" v-if="dre.cor_ah_ano_anterior=='2'"><small>{{dre.perc_ah_ano_anterior|numberFormat}}</small></td>
                                <td class="has-text-centered is-size-7 has-text-info has-text-weight-bold" v-if="dre.cor_ah_ano_anterior=='3'"><small>{{dre.perc_ah_ano_anterior|numberFormat}}</small></td>

                                <td class="has-text-centered is-size-7" v-if="cmbTipoPeriodo != '6' && cmbTipoPeriodo != '7'"><small>{{dre.valor|numberFormat}}</small></td>
                                <td class="has-text-centered is-size-7 has-text-link has-text-weight-bold" v-if="cmbTipoPeriodo != '6' && cmbTipoPeriodo != '7'"><small>{{dre.perc_av_valor_acumulado|numberFormat}}</small></td>

                                <td class="has-text-centered is-size-7" v-if="cmbTipoPeriodo != '6' && cmbTipoPeriodo != '7'"><small>{{dre.valor_periodo_anterior|numberFormat}}</small></td>
                                <td class="has-text-centered is-size-7 has-text-success has-text-weight-bold" v-if="dre.cor_ah_periodo_anterior=='1' && cmbTipoPeriodo != '6' && cmbTipoPeriodo != '7'"><small>{{dre.perc_ah_periodo_anterior|numberFormat}}</small></td>
                                <td class="has-text-centered is-size-7 has-text-danger has-text-weight-bold" v-if="dre.cor_ah_periodo_anterior=='2'&& cmbTipoPeriodo != '6' && cmbTipoPeriodo != '7'"><small>{{dre.perc_ah_periodo_anterior|numberFormat}}</small></td>
                                <td class="has-text-centered is-size-7 has-text-info has-text-weight-bold" v-if="dre.cor_ah_periodo_anterior=='3' && cmbTipoPeriodo != '6' && cmbTipoPeriodo != '7'"><small>{{dre.perc_ah_periodo_anterior|numberFormat}}</small></td>

                            </tr>
                        </tbody>    
                    </table>
                </div>    
            </div>

            <br>

        </div>   

    </div>        

</template>

<script>
    // import _ from 'lodash'
    import {http} from '../../services/config'

    export default {
        name: "DemonstracaoResultadoPeriodo",

        data(){
            return{
                periodosPerfis: [],
                perfisUsuario: [],
                demonstracoesResultados: [],
                demonstracoesResultadosPeriodo: [],                
                linhasDemonstracaoResultado: [],
                tiposPeriodo: [],
                cmbPerfil: 0,
                cmbPeriodoPerfil: 0,
                cmbDemonstracaoResultadoVersao: 0,
                cmbTipoPeriodo: 0,
                anoMesReferencia: "",
                anoMesMesAnteriorReferencia: "",
                anoMesAnoAnteriorReferencia: "",
                anoMes3AnosAnteriorReferencia: "",
                anoMes5AnosAnteriorReferencia: "",
                anoMes10AnosAnteriorReferencia: "",
                showInfo: false,
                isProcessing: false
            }
        },

        methods: {
            filterRecords: function(){
                if (this.cmbPerfil == 0) {
                    alert('Selecione um perfil')
                    return
                }
                if (this.cmbPeriodoPerfil == 0) {
                    alert('Selecione um período')
                    return
                }
                if (this.cmbDemonstracaoResultadoVersao == 0) {
                    alert('Selecione um demonstrativo de resultado')
                    return
                }
                http.get('demonstracaoResultadoPeriodo/listar/', {params: {
                    idPeriodoPerfil: this.cmbPeriodoPerfil.id
                }}).then(response => {
                    this.demonstracoesResultadosPeriodo = response.data.results

                    if (this.demonstracoesResultadosPeriodo.length != 0) {
                        this.showInfo = true
                    } else {
                        this.showInfo = false
                        alert('Não há dados associados aos parâmetros informados!')
                    }
                })

            },

            selectTipoPeriodo: function() {
                this.cmbPerfil = 0
                this.cmbPeriodoPerfil = 0
                this.cmbDemonstracaoResultadoVersao = 0
                this.demonstracoesResultadosPeriodo = []
                this.anoMesReferencia = ""
                this.anoMesMesAnteriorReferencia = ""
                this.anoMesAnoAnteriorReferencia = ""
                this.anoMes3AnosAnteriorReferencia = ""
                this.anoMes5AnosAnteriorReferencia = ""
                this.anoMes10AnosAnteriorReferencia = ""

            },


            selectPerfil: function() {
                http.get('periodoPerfil/listar/', {params: {
                    idPerfil: this.cmbPerfil,
                    idTipoPeriodo: this.cmbTipoPeriodo,
                    semPaginacao: 'S'
                }}).then(response => {
                    this.periodosPerfis = response.data.results
                    if (this.periodosPerfis.length == 0) {
                        alert('Não há períodos associados ao perfil selecionado!')
                    }
                })
            },

            selectPeriodoPerfil: function() {
                http.get('demonstracaoResultadoVersao/listarDREs/', {params: {
                    idVersaoPlanoContas: this.cmbPeriodoPerfil.versao_plano_contas.id,
                }}).then(response => {
                    this.demonstracoesResultados = response.data.results
                    this.anoMesReferencia = this.cmbPeriodoPerfil.periodo.data
                    this.anoMesMesAnteriorReferencia = this.cmbPeriodoPerfil.periodo.data_mes_anterior
                    this.anoMesAnoAnteriorReferencia = this.cmbPeriodoPerfil.periodo.data_ano_anterior
                    this.anoMes3AnosAnteriorReferencia = this.cmbPeriodoPerfil.periodo.data_3anos_anteriores
                    this.anoMes5AnosAnteriorReferencia = this.cmbPeriodoPerfil.periodo.data_5anos_anteriores
                    this.anoMes10AnosAnteriorReferencia = this.cmbPeriodoPerfil.periodo.data_10anos_anteriores

                    if (this.demonstracoesResultados.length == 0) {
                        alert('Não há demonstrativos de resultados associados a versão associada ao período selecionado!')
                    }
                })

                if (this.cmbDemonstracaoResultadoVersao != 0) {
                    this.filterRecords()
                }

            },

            processar: function() {
                if (this.cmbPerfil == 0) {
                    alert('Perfil não selecionado!')
                    return
                }

                if (this.cmbPeriodoPerfil == 0) {
                    alert('Período não selecionado!')
                    return
                }

                if (this.cmbDemonstracaoResultadoVersao == 0) {
                    alert('Demonstração de Resultado não selecionado!')
                    return
                }

                if (window.confirm('Confirma o processamento do demonstrativo de resultado?')) {
                    this.isProcessing = true
                    http.put('/demonstracaoResultadoPeriodo/processar/'+this.cmbPeriodoPerfil.id, this.cmbDemonstracaoResultadoVersao).then(response =>{
                        if (response.status == 204) {
                            this.filterRecords()
                            this.isProcessing = false
                            alert('Processamento concluído!')
                        }
                    }).catch(e => {
                        this.isProcessing = false
                        console.log(e);
                    })
                }


            }

        },

        mounted: function() {
        },
        
        created: function() {
            http.get('compartilharPerfis/listarPerfis').then(response => {
                    this.perfisUsuario = response.data.results
            })

            http.get('tiposPeriodo/listar').then(response => {
                this.tiposPeriodo = response.data.results
            })

        }

    }
</script>

<style scoped>
</style>