<template>
  <div id="app">

    <nav class="navbar is-link is-fixed-top" role="navigation" aria-label="main navigation">

      <div class="navbar-brand">
        <p class="navbar-item is-size-3">
          LineApps
        </p>

        <a role="button" class="navbar-burger burger" aria-label="menu" aria-expanded="false" data-target="navbarBasicExample" @click="showNav = !showNav" :class="{ 'is-active': showNav }">
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>
    
      <div id="navbarBasicExample" class="navbar-menu" :class="{ 'is-active': showNav }">

        <div class="navbar-item">        
          <div class="field is-horizontal">
            <div class="field-body">
              <div class="field">
                <div class="select is-primary">
                  <select v-model="produtoUsuario" @change="selectModulo()">
                    <option value="0" disabled>Acessar...</option>
                    <option :class="classOptionModule(papp.produto_app.sigla)" v-for="papp in produtoAppUsuario" :key="papp.produto_app.id" :value="papp.produto_app.sigla" :disabled="!papp.e_ativo">
                        {{papp.produto_app.descricao}}
                    </option>
                  </select>

                </div>        
              </div>  
            </div>   
          </div>  
        </div>  

        <div class="navbar-start"  v-if="estaAutenticado">
          <div class="navbar-start" v-if="modulo == '2' || modulo == '3'">
            <a class="navbar-item" v-if="modulo == '2'">
              Simulação
            </a>

            <div class="navbar-item has-dropdown is-hoverable">
              <a class="navbar-link">
                Administração
              </a>

              <div class="navbar-dropdown">
                <a class="navbar-item" v-if="modulo == '2'">
                  Modelos
                </a>
                <hr class="navbar-divider" v-if="modulo == '2'">
                <a class="navbar-item" v-if="modulo == '2'">
                  Tipos de Demonstrações de Resultados
                </a>
                <a class="navbar-item" v-if="modulo == '2'">
                  Unidades de Medidas
                </a>
                <hr class="navbar-divider" v-if="modulo == '2'">

                <router-link to="planoDeContas" class="navbar-item">
                  Versões
                </router-link>

                <hr class="navbar-divider">

                <router-link to="bpVersao" class="navbar-item">
                  Balanços Patrimoniais
                </router-link>

                <router-link to="dreVersao" class="navbar-item">
                  DRE's
                </router-link>

                <hr class="navbar-divider">

                <router-link to="outrasInformacoesVersao" class="navbar-item">
                  Outras Informações
                </router-link>

                <hr class="navbar-divider">

                <router-link to="indicador" class="navbar-item">
                  Indicadores Gerenciais
                </router-link>

                <router-link to="grafico" class="navbar-item">
                  Gráficos
                </router-link>

                <router-link to="ValorReferenciaIndicador" class="navbar-item">
                  Valores de Referência
                </router-link>

                <hr class="navbar-divider">

                <router-link to="perfil" class="navbar-item">
                  Perfil
                </router-link>


              </div>
            </div>

            <div class="navbar-item has-dropdown is-hoverable">
              <a class="navbar-link">
                Análises
              </a>

              <div class="navbar-dropdown">
                <router-link to="PeriodoPerfil" class="navbar-item">
                  Períodos
                </router-link>

                <hr class="navbar-divider" v-if="modulo == '3'">

                <router-link to="Balancete" class="navbar-item">
                  Balancete
                </router-link>

                <router-link to="bpPeriodo" class="navbar-item">
                  Balanço Patrimonial
                </router-link>

                <router-link to="drePeriodo" class="navbar-item">
                  DRE
                </router-link>

                <hr class="navbar-divider">

                <router-link to="outrasInformacoesPeriodo" class="navbar-item">
                  Outras Informações
                </router-link>

                <hr class="navbar-divider">

                <router-link to="IndicadorPeriodo" class="navbar-item">
                  Dashboard
                </router-link>
              </div>
              
            </div>
            

          </div>
          
          <div class="navbar-item has-dropdown is-hoverable" v-if="modulo == '1'">
            <a class="navbar-link">
              Gestão da Carteira
            </a>
    
            <div class="navbar-dropdown">
              <router-link to="dashboard" class="navbar-item">
                Dashboard
              </router-link>
              <router-link to="periodo" class="navbar-item">
                Períodos
              </router-link>

              <router-link to="aportes" class="navbar-item">
                Aportes
              </router-link>
              <hr class="navbar-divider">

              <router-link to="mapaProventos" class="navbar-item">
                Mapa da Geração de Renda
              </router-link>
              <hr class="navbar-divider">
              
              <!-- <a class="navbar-item">
                Imposto de Renda
              </a>
              <hr class="navbar-divider"> -->

              <router-link to="modelagemCarteira" class="navbar-item">
                Modelagem de Carteiras
              </router-link>

            </div>

          </div>

          <div class="navbar-item has-dropdown is-hoverable"  v-if="modulo == '1'">
            <a class="navbar-link">
              Corretoras
            </a>
            <div class="navbar-dropdown">
              <router-link to="corretoraExtrato" class="navbar-item">
                Extratos
              </router-link>
              <router-link to="notaNegociacao" class="navbar-item">
                Notas de Negociação
              </router-link>
              <router-link to="corretoraSelecao" class="navbar-item">
                Seleção
              </router-link>
            </div>
          </div>          

          <div class="navbar-item has-dropdown is-hoverable"  v-if="modulo == '1'">
            <a class="navbar-link">
              Mais
            </a>
            <div class="navbar-dropdown">
              <router-link to="benchmark" class="navbar-item">
                Benchmarks
              </router-link>
              <router-link to="empresa" class="navbar-item">
                Empresas
              </router-link>
              <router-link to="ativo" class="navbar-item">
                Ativos
              </router-link>
            </div>
          </div>          
        </div>
    
        <div class="navbar-end">
          <div class="navbar-item">
            <small>
              <div class="field is-horizontal">
                <div class="field-body">
                  <div class="field">
                    <p class="control is-small has-icons-left">
                        <input class="input is-small" type="text" placeholder="Usuário" v-model="usuario" :disabled="estaAutenticado">
                        <span class="icon is-small is-left">
                          <i class="fas fa-user"></i>
                        </span>
                    </p>
                  </div>
                  <div class="field">
                    <p class="control has-icons-left has-icons-right">
                      <input class="input is-success is-small" type="password" placeholder="Senha" v-model="senha" :disabled="estaAutenticado">
                      <span class="icon is-small is-left">
                        <i class="fas fa-lock"></i>
                      </span>
                    </p>
                  </div>

                  <div class="field">
                    <p class="control">
                        <button class="button is-success is-small is-rounded" @click="login()" v-show="!estaAutenticado">
                          Login
                        </button>
                        <button class="button is-success is-small has-tooltip-bottom is-rounded" data-tooltip="Sair" @click="logout()" v-show="estaAutenticado">
                          <span class="fas fa-door-open"></span>
                        </button>
                        <button class="button is-success is-small has-tooltip-bottom is-rounded" data-tooltip="Trocar Senha" @click="changePassword()" v-show="estaAutenticado">
                          <span class="fas fa-key"></span>
                        </button>
                    </p>
                  </div>    
                  
                  
                </div>
              </div>
            </small>
          </div>
        </div>
      </div>
    </nav>    
    <br>
    <br>
    <br>

    <div class="modal" v-bind:class="{ 'is-active': isShowModal }">
      <div class="modal-background" v-on:click="isShowModal = false"></div>
      <div class="modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title">Trocar Senha</p>
            <button class="delete" aria-label="close" v-on:click="isShowModal = false"></button>
          </header>
          <section class="modal-card-body">
              <form>
                  <div class="field is-horizontal">
                    <div class="field-label is-normal">
                          <label class="label"><small>Senha Atual</small></label>
                      </div>                            
                      <div class="field-body">
                          <div class="field is-fullwidth">
                              <input class="input" type="password" placeholder="Informe sua senha atual" v-model="senhaAntiga">
                          </div>
                      </div>
                  </div>                      

                  <div class="field is-horizontal">
                      <div class="field-label is-normal">
                          <label class="label"><small>Nova Senha</small></label>
                      </div>                            
                      <div class="field-body">
                          <div class="field is-fullwidth">
                              <input class="input" type="password" placeholder="Informe a sua nova senha" v-model="novaSenha">
                          </div>
                      </div>
                  </div>    

                  <div class="field is-horizontal">
                      <div class="field-label is-normal">
                          <label class="label"><small>Confirme</small></label>
                      </div>                            
                      <div class="field-body">
                          <div class="field is-fullwidth">
                              <input class="input" type="password" placeholder="Digite novamente a nova senha" v-model="novaSenhaConfirmar">
                          </div>
                      </div>
                  </div>
              </form>  
          </section>

          <footer class="modal-card-foot">
            <button class="button is-success" @click="save()">Salvar</button>
            <button class="button" v-on:click="isShowModal = false">Cancelar</button>
          </footer>
      </div>
    </div>        

    <router-view>
    </router-view>
  </div>
</template>

<script>
  import {http} from './services/config'
  export default {
    name: 'App',

    data(){
      return {
        usuario: null,
        senha: null,
        estaAutenticado: false,
        showNav: false,
        isShowModal: false,
        modulo: null,
        novaSenha: null,
        novaSenhaConfirmar: null,
        senhaAntiga: null,
        produtoAppUsuario: [],
        produtoUsuario: "0"
      }
    },

    methods: {
      login: function() {
        http.post('rest_auth/login/', { 
          username: this.usuario, 
          password: this.senha 
        }).then(response =>{
          if (response.status == 200) {
            this.estaAutenticado = true
            this.$store.state.token = response.data.key
            // this.$store.commit('setToken', response.data.key)
            http.defaults.headers.common['Authorization'] = 'Token '+this.$store.state.token
            http.get('rest_auth/user/', {username: this.usuario}).then(response => {
              this.$store.state.idUser = response.data.pk
              // this.$store.commit('setUsername', response.data.pk)

              http.get('produtoAppUsuario/listarProdutosApp').then(response => {
                this.produtoAppUsuario = response.data.results
                if (this.produtoAppUsuario.length == 1) {
                  this.produtoUsuario = this.produtoAppUsuario[0].produto_app.sigla
                  this.selectModulo()
                } else {
                  this.modulo = '0'
                }
                this.$store.state.selectedModule = this.modulo
              })


            })
          }
        }).catch(function(){
          alert("Credenciais inválidas!")
        })
      },
      logout: function() {
        http.post('/rest_auth/logout/').then(response =>{
          this.usuario = null
          this.senha = null
          this.estaAutenticado = false
          this.$store.state.token = null
          this.$store.state.idUser = null
          this.produtoUsuario = "0"
          this.modulo = "0"
          this.produtoAppUsuario = []
          this.$store.state.selectedModule = this.modulo
          http.defaults.headers.common['Authorization'] = null
          if ( response.status == 200 ) {
            if (this.modulo == "1") {
              alert("Até breve e bons investimentos!")
            } else {
              alert("Até breve!")
            }
            if (window.location.href != 'http://localhost:8080/#/' && window.location.href != 'https://pgpaf.com.br/#/' ) {
              this.$router.push('/');
            }
          }
        })
      },
      changePassword: function() {
        this.senhaAntiga = null
        this.novaSenha = null
        this.novaSenhaConfirmar = null
        this.isShowModal = true              
      },

      selectModulo: function() {
        if (this.produtoUsuario == "QUIVER") {
          this.modulo = "1"
        } else if ( this.produtoUsuario == "PROMODEL") {
          this.modulo = "2"
        } else {
          this.modulo = "3"
        }
        this.$store.state.selectedModule = this.modulo
        if (window.location.href != 'http://localhost:8080/#/' && window.location.href != 'https://pgpaf.com.br/#/' ) {
              this.$router.push('/');
        }
      },

      classOptionModule: function(opcao) {
        if (opcao == 'BOARD') {
          return 'title has-text-danger is-5' 
        } else if (opcao == 'QUIVER') {
          return 'title has-text-warning is-5'
        } else {
          return 'title has-text-success is-5'
        }

      },
      save: function() {
          if (this.senhaAntiga == null) {
              alert('Senha atual não informada!')
              return
          }

          if (this.novaSenha == null) {
              alert('Nova senha não informada!')
              return
          }

          if (this.novaSenhaConfirmar == null) {
              alert('Confirmação da nova senha não informada!')
              return
          }

          if (this.novaSenhaConfirmar != this.novaSenha) {
              alert('A senha de confirmação está diferente da nova senha informada')
              return
          }

          http.post('/rest_auth/password/change/', { 
            new_password1: this.novaSenha, 
            new_password2: this.novaSenhaConfirmar, 
            old_password: this.senhaAntiga 
          }).then(response =>{
            if (response.status == 200) {
              alert("Senha modificada com sucesso")
              this.isShowModal = false
              this.logout()
            } 
          }).catch(e =>{
            if (e.response.data.new_password2 != undefined) {
              alert(e.response.data.new_password2[0])
            }

            if (e.response.data.new_password1 != undefined) {
              alert(e.response.data.new_password1[0])
            }

            if (e.response.data.old_password != undefined) {
              alert(e.response.data.old_password[0])
            }

          })

      }

    }
  }
</script>

<style>
</style>

