<template>
    <div>
        <br>
        <h5 class="title is-5">Balanco Patrimonial
            <button class="button is-link is-light is-pulled-right is-small" v-on:click="processar()" :disabled="this.cmbPeriodoPerfil.estaFechado">
                Processar
            </button>  
        </h5>

        <form>
            <div class="field is-horizontal">
                <div class="field-body">

                    <div class="field is-narrow">
                        <div class="control">
                            <div class="select is-narrow">
                                <select v-model="cmbTipoPeriodo" @change="selectTipoPeriodo()">
                                    <option value="0" disabled>Selecione um tipo de período</option>
                                    <option v-for="td in tiposPeriodo" :key="td.id" :value="td.id">
                                        {{td.descricao}}
                                    </option>
                                </select>
                            </div>    
                        </div>
                    </div>  

                    <div class="field is-narrow">
                        <div class="control">
                            <div class="select is-fullwidth">
                                <select v-model="cmbPerfil" @change="selectPerfil()">
                                    <option value="0" disabled>Selecione um perfil</option>
                                    <option v-for="perfilUsuario in perfisUsuario" :key="perfilUsuario.id" :value="perfilUsuario.perfil.id">
                                        {{perfilUsuario.perfil.descricao}}
                                    </option>
                                </select>
                            </div>    
                        </div>
                    </div>  

                    <div class="field is-narrow">
                        <div class="control">
                            <div class="select is-narrow">
                                <select v-model="cmbPeriodoPerfil" @change="selectPeriodoPerfil()">
                                    <option value="0" disabled>Selecione um período</option>
                                    <option v-for="periodoPerfil in periodosPerfis" :key="periodoPerfil.id" :value="periodoPerfil">
                                        {{periodoPerfil.periodo.descricao}}
                                    </option>
                                </select>
                            </div>    
                        </div>
                    </div>  

                    <div class="field is-narrow">
                        <div class="control">
                            <div class="select is-narrow">
                                <select v-model="cmbBalancoPatrimonialVersao">
                                    <option value="0" disabled>Selecione um balanço patrimonial</option>
                                    <option v-for="bp in balancosPatrimoniais" :key="bp.id" :value="bp.balanco_patrimonial_versao">
                                        {{bp.balanco_patrimonial_versao.descricao}}
                                    </option>
                                </select>
                            </div>    
                        </div>
                    </div>  

                    <div class="field">                    
                        <button type='submit' class="button is-link" @click.stop.prevent="filterRecords">Filtrar</button>
                    </div>    
                </div>

            </div>
        </form>  

        <hr>

        <div class="modal" v-bind:class="{ 'is-active': isProcessing }">
            <div class="modal-background"></div>
            <div class="modal-content">
                <progress class="progress is-large is-primary" max="100">50%</progress>
            </div>
        </div>     
        
        <div class="columns" v-show="this.analiseDinamicaBalanco.length != 0">

            <div class="column is-three-fifths">
                <div id="chart">
                    <apexchart type="treemap" height="350" :options="chartOptions" :series="series"></apexchart>
                </div>   
            </div>


            <div class="column">

                <div class="card">
                    <header class="card-header has-background-primary">
                        <p class="card-header-title has-text-white-bis">
                            Situação
                        </p>
                    </header>
                    <div class="card-content has-background-primary-light">
                        <div class="content">
                        <h1 class="has-text-success-dark is-size-4 has-text-centered">{{situacaoAnaliseDinamica}}</h1>
                        </div>
                    </div>

                    <footer class="card-footer">
                        <p class="is-size-7">
                            {{helpSituacao}}
                        </p>
                    </footer>
                </div>

                <br>

                <div class="table-container">                
                    <table class="table is-fullwidth is-hoverable">
                        <thead>
                            <tr>
                                <th class="has-text-left is-size-7"><small>Indicador</small></th>
                                <th class="has-text-right is-size-7"><small>Valor</small></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="is-size-7" data-tooltip="Ativo Circulante Financeiro - Passivo Circulante Financeiro"><small><strong>T - Saldo em Tesouraria</strong></small></td>
                                <td class="has-text-right is-size-7"><small>{{valorST|numberFormat}}</small></td>
                            </tr>            
                            <tr>
                                <td class="is-size-7" data-tooltip="Ativo Circulante Operacional - Passivo Circulante Operacional"><small><strong>NCG - Necessidade de Capita de Giro</strong></small></td>
                                <td class="has-text-right is-size-7"><small>{{valorNCG|numberFormat}}</small></td>
                            </tr>            
                            <tr>
                                <td class="is-size-7" data-tooltip="Passivo Não Circulante - Ativo Não Circulante"><small><strong>CDG - Capital de Giro</strong></small></td>
                                <td class="has-text-right is-size-7"><small>{{valorCDG|numberFormat}}</small></td>
                            </tr>            
                        </tbody>
                    </table>   
                </div> 

            </div>

        </div>

        <div class="columns" v-show="this.showInfo">
            <div class="column is-half">
                <nav class="panel is-success" v-if="cmbBalancoPatrimonialVersao != 0">
                    <p class="panel-heading">
                        ATIVO
                    </p>
                    <div class="panel-block">
                        <div class="table-container">                
                            <table class="table is-fullwidth is-hoverable">
                                <thead>
                                    <tr>
                                        <th class="has-text-left is-size-7"><small></small></th>
                                        <th class="has-text-centered is-size-7"><small>{{anoMesReferencia|datePorTipo(cmbTipoPeriodo)}}</small></th>
                                        <th class="has-text-centered is-size-7 has-text-grey-light">AV%</th>
                                        <th class="has-text-centered is-size-7"><small>{{anoMesMesAnteriorReferencia|datePorTipo(cmbTipoPeriodo)}}</small></th>
                                        <th class="has-text-centered is-size-7 has-text-grey-light">AH%</th>
                                        <th class="has-text-centered is-size-7" v-if="cmbTipoPeriodo != 6"><small>{{anoMesAnoAnteriorReferencia|datePorTipo(cmbTipoPeriodo)}}</small></th>
                                        <th class="has-text-centered is-size-7 has-text-grey-light" v-if="cmbTipoPeriodo != 6">AH%</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="bp in balancosPatrimoniaisPeriodo" :key="bp.id">
                                        <td :class="renderCoresADB(bp.balanco_patrimonial_versao_config)" v-if="bp.balanco_patrimonial_versao_config.e_negrito && bp.balanco_patrimonial_versao_config.destino=='A'"><small><strong>{{bp.balanco_patrimonial_versao_config.descricao}}</strong></small></td>
                                        <td :class="renderCoresADB(bp.balanco_patrimonial_versao_config)" v-if="!bp.balanco_patrimonial_versao_config.e_negrito && bp.balanco_patrimonial_versao_config.destino=='A'"><small>{{bp.balanco_patrimonial_versao_config.descricao}}</small></td>

                                        <td class="has-text-centered is-size-7" v-if="bp.balanco_patrimonial_versao_config.destino=='A'"><small>{{bp.valor|numberFormat}}</small></td>
                                        <td class="has-text-centered is-size-7 has-text-link has-text-weight-bold" v-if="bp.balanco_patrimonial_versao_config.destino=='A'"><small>{{bp.perc_av|numberFormat}}</small></td>

                                        <td class="has-text-centered is-size-7" v-if="bp.balanco_patrimonial_versao_config.destino=='A'"><small>{{bp.valorMesAnterior|numberFormat}}</small></td>
                                        <td class="has-text-centered is-size-7 has-text-success has-text-weight-bold" v-if="bp.balanco_patrimonial_versao_config.destino=='A' && bp.cor_ah_mes_anterior=='1'"><small>{{bp.perc_ah_mes_anterior|numberFormat}}</small></td>
                                        <td class="has-text-centered is-size-7 has-text-danger has-text-weight-bold" v-if="bp.balanco_patrimonial_versao_config.destino=='A' && bp.cor_ah_mes_anterior=='2'"><small>{{bp.perc_ah_mes_anterior|numberFormat}}</small></td>
                                        <td class="has-text-centered is-size-7 has-text-info has-text-weight-bold" v-if="bp.balanco_patrimonial_versao_config.destino=='A' && bp.cor_ah_mes_anterior=='3'"><small>{{bp.perc_ah_mes_anterior|numberFormat}}</small></td>

                                        <td class="has-text-centered is-size-7" v-if="bp.balanco_patrimonial_versao_config.destino=='A' && cmbTipoPeriodo != '6'"><small>{{bp.valorAnoAnterior|numberFormat}}</small></td>
                                        <td class="has-text-centered is-size-7 has-text-success has-text-weight-bold" v-if="bp.balanco_patrimonial_versao_config.destino=='A' && bp.cor_ah_ano_anterior=='1' && cmbTipoPeriodo != '6'"><small>{{bp.perc_ah_ano_anterior|numberFormat}}</small></td>
                                        <td class="has-text-centered is-size-7 has-text-danger has-text-weight-bold" v-if="bp.balanco_patrimonial_versao_config.destino=='A' && bp.cor_ah_ano_anterior=='2' && cmbTipoPeriodo != '6'"><small>{{bp.perc_ah_ano_anterior|numberFormat}}</small></td>
                                        <td class="has-text-centered is-size-7 has-text-info has-text-weight-bold" v-if="bp.balanco_patrimonial_versao_config.destino=='A' && bp.cor_ah_ano_anterior=='3' && cmbTipoPeriodo != '6'"><small>{{bp.perc_ah_ano_anterior|numberFormat}}</small></td>

                                    </tr>
                                </tbody>    
                            </table>
                        </div>    
                    </div>
                </nav>
            </div>

            <div class="column is-half">
                <nav class="panel is-link" v-if="cmbBalancoPatrimonialVersao != 0">
                    <p class="panel-heading">
                        PASSIVO
                    </p>
                    <div class="panel-block">
                        <div class="table-container">                
                            <table class="table is-fullwidth is-hoverable">
                                <thead>
                                    <tr>
                                        <th class="has-text-left is-size-7"><small></small></th>
                                        <th class="has-text-centered is-size-7"><small>{{anoMesReferencia|datePorTipo(cmbTipoPeriodo)}}</small></th>
                                        <th class="has-text-centered is-size-7 has-text-grey-light">AV%</th>
                                        <th class="has-text-centered is-size-7"><small>{{anoMesMesAnteriorReferencia|datePorTipo(cmbTipoPeriodo)}}</small></th>
                                        <th class="has-text-centered is-size-7 has-text-grey-light">AH%</th>
                                        <th class="has-text-centered is-size-7" v-if="cmbTipoPeriodo != '6'"><small>{{anoMesAnoAnteriorReferencia|datePorTipo(cmbTipoPeriodo)}}</small></th>
                                        <th class="has-text-centered is-size-7 has-text-grey-light" v-if="cmbTipoPeriodo != '6'">AH%</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="bp in balancosPatrimoniaisPeriodo" :key="bp.id">
                                        <td :class="renderCoresADB(bp.balanco_patrimonial_versao_config)" v-if="bp.balanco_patrimonial_versao_config.e_negrito && bp.balanco_patrimonial_versao_config.destino=='P'"><small><strong>{{bp.balanco_patrimonial_versao_config.descricao}}</strong></small></td>
                                        <td :class="renderCoresADB(bp.balanco_patrimonial_versao_config)" v-if="!bp.balanco_patrimonial_versao_config.e_negrito && bp.balanco_patrimonial_versao_config.destino=='P'"><small>{{bp.balanco_patrimonial_versao_config.descricao}}</small></td>

                                        <td class="has-text-centered is-size-7" v-if="bp.balanco_patrimonial_versao_config.destino=='P'"><small>{{bp.valor|numberFormat}}</small></td>
                                        <td class="has-text-centered is-size-7 has-text-link has-text-weight-bold" v-if="bp.balanco_patrimonial_versao_config.destino=='P'"><small>{{bp.perc_av|numberFormat}}</small></td>

                                        <td class="has-text-centered is-size-7" v-if="bp.balanco_patrimonial_versao_config.destino=='P'"><small>{{bp.valorMesAnterior|numberFormat}}</small></td>
                                        <td class="has-text-centered is-size-7 has-text-success has-text-weight-bold" v-if="bp.balanco_patrimonial_versao_config.destino=='P' && bp.cor_ah_mes_anterior=='1'"><small>{{bp.perc_ah_mes_anterior|numberFormat}}</small></td>
                                        <td class="has-text-centered is-size-7 has-text-danger has-text-weight-bold" v-if="bp.balanco_patrimonial_versao_config.destino=='P' && bp.cor_ah_mes_anterior=='2'"><small>{{bp.perc_ah_mes_anterior|numberFormat}}</small></td>
                                        <td class="has-text-centered is-size-7 has-text-info has-text-weight-bold" v-if="bp.balanco_patrimonial_versao_config.destino=='P' && bp.cor_ah_mes_anterior=='3'"><small>{{bp.perc_ah_mes_anterior|numberFormat}}</small></td>

                                        <td class="has-text-centered is-size-7" v-if="bp.balanco_patrimonial_versao_config.destino=='P' && cmbTipoPeriodo != '6'"><small>{{bp.valorAnoAnterior|numberFormat}}</small></td>
                                        <td class="has-text-centered is-size-7 has-text-success has-text-weight-bold" v-if="bp.balanco_patrimonial_versao_config.destino=='P' && bp.cor_ah_ano_anterior=='1' && cmbTipoPeriodo != '6'"><small>{{bp.perc_ah_ano_anterior|numberFormat}}</small></td>
                                        <td class="has-text-centered is-size-7 has-text-danger has-text-weight-bold" v-if="bp.balanco_patrimonial_versao_config.destino=='P' && bp.cor_ah_ano_anterior=='2' && cmbTipoPeriodo != '6'"><small>{{bp.perc_ah_ano_anterior|numberFormat}}</small></td>
                                        <td class="has-text-centered is-size-7 has-text-info has-text-weight-bold" v-if="bp.balanco_patrimonial_versao_config.destino=='P' && bp.cor_ah_ano_anterior=='3' && cmbTipoPeriodo != '6'"><small>{{bp.perc_ah_ano_anterior|numberFormat}}</small></td>
                                    </tr>
                                </tbody>    
                            </table>
                        </div>    
                    </div>
                </nav> 
            </div>

        </div>        
    </div>     

</template>

<script>
    import {http} from '../../services/config'

    export default {
        name: "BalancoPatrimonialPeriodo",

        data(){
            return{
                periodosPerfis: [],
                perfisUsuario: [],
                balancosPatrimoniais: [],
                balancosPatrimoniaisPeriodo: [],    
                analiseDinamicaBalanco: [],            
                linhasBalancoPatrimonial: [],
                tiposPeriodo: [],
                series: [],
                chartOptions: {
                    legend: {
                        show: false
                    },
                    chart: {
                        height: 350,
                        type: 'treemap'
                    },
                    title: {
                        text: 'Análise Dinâmica do Balanço',
                        align: 'center'
                    },
                    colors: [
                        '#3B93A5',
                        '#3B93A5',
                        '#3B93A5',
                        '#D43F97',
                        '#D43F97',
                        '#D43F97',
                    ],
                    plotOptions: {
                        treemap: {
                            distributed: true,
                            enableShades: false
                        }
                    }
                },
                situacaoAnaliseDinamica: null,
                helpSituacao: null,
                valorNCG: 0,
                valorCDG: 0,
                valorST: 0,
                cmbPerfil: 0,
                cmbPeriodoPerfil: 0,
                cmbBalancoPatrimonialVersao: 0,
                cmbTipoPeriodo: 0,
                anoMesReferencia: "",
                anoMesMesAnteriorReferencia: "",
                anoMesAnoAnteriorReferencia: "",
                anoMes3AnosAnteriorReferencia: "",
                anoMes5AnosAnteriorReferencia: "",
                anoMes10AnosAnteriorReferencia: "",
                showInfo: false,
                isProcessing: false
            }
        },

        methods: {

            renderCoresADB: function(config) {
                var cor 

                if (config.tipo_grupo_analise_dinamica == 'CF') {
                    cor = "has-background-danger has-text-white"
                } else if (config.tipo_grupo_analise_dinamica == 'CO') {
                    cor = "has-background-info has-text-white"
                } else if (config.tipo_grupo_analise_dinamica == 'NC') {
                    cor = "has-background-warning"
                }
                else {
                    cor = ""
                }

                return "is-size-6 " + cor

            },

            filterRecords: function(){
                if (this.cmbPerfil == 0) {
                    alert('Selecione um perfil')
                    return
                }
                if (this.cmbPeriodoPerfil == 0) {
                    alert('Selecione um período')
                    return
                }
                if (this.cmbBalancoPatrimonialVersao == 0) {
                    alert('Selecione um balanço patrimonial')
                    return
                }

                this.analiseDinamicaBalanco = []
                this.series = []
                this.situacaoAnaliseDinamica = null
                this.helpSituacao = null
                this.valorNCG = 0
                this.valorCDG = 0
                this.valorST = 0

                http.get('balancoPatrimonialPeriodo/listar/', {params: {
                    idPeriodoPerfil: this.cmbPeriodoPerfil.id
                }}).then(response => {
                    this.balancosPatrimoniaisPeriodo = response.data.results

                    if (this.balancosPatrimoniaisPeriodo.length != 0) {
                        this.showInfo = true

                        http.get('analiseDinamicaBalanco/listar/', {params: {
                            idPeriodoPerfil: this.cmbPeriodoPerfil.id,
                            idBalancoPatrimonialVersao: this.cmbBalancoPatrimonialVersao.id
                        }}).then(response => {
                            this.analiseDinamicaBalanco = response.data.results
                            this.series = [
                                {data: [
                                    {   x: 'Ativo Circulante Financeiro',
                                        y: this.analiseDinamicaBalanco[0].valor_acf.toFixed(2) },
                                    {   x: 'Ativo Circulante Operacional',
                                        y: this.analiseDinamicaBalanco[0].valor_aco.toFixed(2) },
                                    {   x: 'Ativo Não Circulante',
                                        y: this.analiseDinamicaBalanco[0].valor_anc.toFixed(2) },
                                    {   x: 'Passivo Circulante Financeiro',
                                        y: this.analiseDinamicaBalanco[0].valor_pcf.toFixed(2) },
                                    {   x: 'Passivo Circulante Operacional',
                                        y: this.analiseDinamicaBalanco[0].valor_pco.toFixed(2) },
                                    {   x: 'Passivo Não Circulante',
                                        y: this.analiseDinamicaBalanco[0].valor_pnc.toFixed(2) },
                            ]}]

                            if (this.analiseDinamicaBalanco[0].situacao == '1') {
                                this.situacaoAnaliseDinamica = "Excelente"
                                this.helpSituacao = "Empresa com este padrão de estrutura patrimonial revela excelente situação financeira, com sua liquidez (T>0) garantida pela existência de financiamento estratégico líquido (CDG>0) e de sobra de financiamento com passivos operacionais (NCG<0)."
                            } else if (this.analiseDinamicaBalanco[0].situacao == '2') {
                                this.situacaoAnaliseDinamica = "Sólida"
                                this.helpSituacao = "Empresa com este padrão de estrutura patrimonial revela sólida situação financeira, com sua liquidez (T>0) garantida pela existencia de financiamento estratégico líquido (CDG>0) superior à sua necessidade de investimento operacional em giro (NCG>)."
                            } else if (this.analiseDinamicaBalanco[0].situacao == '3') {
                                this.situacaoAnaliseDinamica = "Insatisfatória"
                                this.helpSituacao = "Empresa com este padrão de estrutura patrimonial revela insatisfatória situação financeira, pois precisa estar utilizando dívida líquida onerosa (T<0), além de financiamento estratégico líquido (CDG>0), na cobertura de sua necessidade de investimento operacional em giro (NCG>0)."
                            } else if (this.analiseDinamicaBalanco[0].situacao == '4') {
                                this.situacaoAnaliseDinamica = "Alto risco"
                                this.helpSituacao = "Empresa com este padrão de estrutura patrimonial revela situação financeira de alto risco, uma vez que financia seu investimento líquido em ativos estratégicos (CDG<0) e garante sua liquidez (T>0) usando, exclusivamente, sobra de financiamento com passivos operacionais (NCG<0)."
                            } else if (this.analiseDinamicaBalanco[0].situacao == '5') {
                                this.situacaoAnaliseDinamica = "Muito ruim"
                                this.helpSituacao = "Empresa com este padrão de estrutura patrimonial revela situação financeira muito ruim, pois precisa estar utilizando dívida liquida onerosa (T<0) e sobra de financiamento com passivos operacionais (NCG<0), na cobertura de seu investimento líquido em ativos estratégicos (CDG>0)."
                            } else if (this.analiseDinamicaBalanco[0].situacao == '6') {
                                this.situacaoAnaliseDinamica = "Pré Insolvencia"
                                this.helpSituacao = "Empresa com este padrão de estrutura patrimonial revela situação de pré-insolvência, uma vez que financia todas as suas necessidades de capital (NCG>0 e CDG<0) usando, exclusivamente, dívida líquida onerosa (T<0)."
                            }

                            this.valorNCG = this.analiseDinamicaBalanco[0].valor_ncg.toFixed(2)
                            this.valorCDG = this.analiseDinamicaBalanco[0].valor_cdg.toFixed(2)
                            this.valorST = this.analiseDinamicaBalanco[0].valor_st.toFixed(2)

                        })

                    } else {
                        this.showInfo = false
                        alert('Não há dados associados aos parâmetros informados!')
                    }
                })

            },

            selectTipoPeriodo: function() {
                this.cmbPerfil = 0
                this.cmbPeriodoPerfil = 0
                this.cmbBalancoPatrimonialVersao = 0
                this.balancosPatrimoniaisPeriodo = []
                this.anoMesReferencia = ""
                this.anoMesMesAnteriorReferencia = ""
                this.anoMesAnoAnteriorReferencia = ""
                this.anoMes3AnosAnteriorReferencia = ""
                this.anoMes5AnosAnteriorReferencia = ""
                this.anoMes10AnosAnteriorReferencia = ""

            },


            selectPerfil: function() {
                http.get('periodoPerfil/listar/', {params: {
                    idPerfil: this.cmbPerfil,
                    idTipoPeriodo: this.cmbTipoPeriodo,
                    semPaginacao: 'S'
                }}).then(response => {
                    this.periodosPerfis = response.data.results
                    if (this.periodosPerfis.length == 0) {
                        alert('Não há períodos associados ao perfil selecionado!')
                    }
                })
            },

            selectPeriodoPerfil: function() {
                http.get('balancoPatrimonialVersao/listarBalancos/', {params: {
                    idVersaoPlanoContas: this.cmbPeriodoPerfil.versao_plano_contas.id,
                }}).then(response => {
                    this.balancosPatrimoniais = response.data.results
                    this.anoMesReferencia = this.cmbPeriodoPerfil.periodo.data
                    this.anoMesMesAnteriorReferencia = this.cmbPeriodoPerfil.periodo.data_mes_anterior
                    this.anoMesAnoAnteriorReferencia = this.cmbPeriodoPerfil.periodo.data_ano_anterior
                    this.anoMes3AnosAnteriorReferencia = this.cmbPeriodoPerfil.periodo.data_3anos_anteriores
                    this.anoMes5AnosAnteriorReferencia = this.cmbPeriodoPerfil.periodo.data_5anos_anteriores
                    this.anoMes10AnosAnteriorReferencia = this.cmbPeriodoPerfil.periodo.data_10anos_anteriores

                    if (this.balancosPatrimoniais.length == 0) {
                        alert('Não há balanços patrimoniais associados a versão associada ao período selecionado!')
                    }
                })

                if (this.cmbBalancoPatrimonialVersao != 0) {
                    this.filterRecords()
                }

            },


            processar: function() {
                if (this.cmbPerfil == 0) {
                    alert('Perfil não selecionado!')
                    return
                }

                if (this.cmbPeriodoPerfil == 0) {
                    alert('Período não selecionado!')
                    return
                }

                if (this.cmbBalancoPatrimonialVersao == 0) {
                    alert('Balanço patrimonial não selecionado!')
                    return
                }

                if (window.confirm('Confirma o processamento do balanco patrimonial?')) {
                    this.isProcessing = true
                    http.put('/balancoPatrimonialPeriodo/processar/'+this.cmbPeriodoPerfil.id, this.cmbBalancoPatrimonialVersao).then(response =>{
                        if (response.status == 204) {
                            this.isProcessing = false
                            this.filterRecords()
                            alert('Processamento concluído!')
                        }
                    }).catch(e => {
                        this.isProcessing = false
                        console.log(e);
                    })
                }

            }

        },



        mounted: function() {
        },
        
        created: function() {
            http.get('compartilharPerfis/listarPerfis').then(response => {
                    this.perfisUsuario = response.data.results
            })

            http.get('tiposPeriodo/listar').then(response => {
                this.tiposPeriodo = response.data.results
            })

        }

    }
</script>

<style scoped>
</style>